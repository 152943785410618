import { FirestoreConnector } from '@craftnote/shared-injectables';
import { Injectable } from '@angular/core';
import { Feature, isFeature } from 'domain-entities';

export { Feature } from 'domain-entities';

export enum FeatureType {
	ORDERS = 'orders',
}

@Injectable({
	providedIn: 'root',
})
export class FeaturesConnector {
	static COLLECTION_NAME = 'features';

	constructor(private readonly connector: FirestoreConnector) { }

	async isActive(companyId: string, feature: keyof Feature): Promise<boolean> {
		if (!companyId || !feature) {
			return false;
		}
		const path = `${FeaturesConnector.COLLECTION_NAME}/${companyId}`;
		try {
			const doc = await this.connector.getDocument<Feature>(path, isFeature);
			return doc[feature];
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	async updateOrCreateFeature(
		companyId: string,
		feature: keyof Feature,
		enabled: boolean,
	): Promise<void> {
		const path = `${FeaturesConnector.COLLECTION_NAME}`;
		try {
			await this.connector.updateDocumentPartial<Feature>(
				path,
				companyId,
				{ [feature]: enabled },
				[],
			);
		} catch (_) {
			try {
				await this.connector.create(path, companyId, { [feature]: enabled }, isFeature);
			} catch (e) {
				console.log(e);
			}
		}
	}
}
