import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { GroupByOption, ProjectSortConfig } from '../../types/project-sort.types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { OrderByType, SortByType } from 'domain-entities';
import { Subject } from 'rxjs';
import { pairwise, startWith, take, takeUntil } from 'rxjs/operators';
import { keys, noop } from 'lodash';
import { Store } from '@ngrx/store';
import { selectHasCurrentUserProfileLimitsFactory } from '@store/selectors/profile-limits.selectors';
import {
	ProjectsProjectGroupingUpdatedEventBuilder,
	ProjectsProjectSortUpdatedEventBuilder,
} from '@generated/events/ProjectsEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';

@Component({
	selector: 'app-project-sort',
	templateUrl: './project-sort.component.html',
	styleUrls: ['./project-sort.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ProjectSortComponent),
			multi: true,
		},
	],
})
export class ProjectSortComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() isMenuOpen = false;
	@Output() openGroupingPaywall = new EventEmitter<void>();

	readonly translationKeysBySortType: { [key in SortByType]?: string } = {
		LAST_EDITED: 'project.sort.lastEdited',
		CREATION_DATE: 'project.sort.creationDate',
		ALPHABETICAL: 'project.sort.alphabetical',
		ORDER_NUMBER: 'project.sort.order_number',
	};

	readonly translationKeysByGroupingType: { [key in GroupByOption]?: string } = {
		NONE: 'project.group.options.none',
		STATUS: 'project.group.options.status',
	};

	readonly OrderByType = OrderByType;

	SortByTypeKeys = keys(this.translationKeysBySortType);
	readonly GroupByOptionKeys = keys(this.translationKeysByGroupingType);

	userHasGroupingProfileLimit$ = this.store.select(
		selectHasCurrentUserProfileLimitsFactory('projectListGrouping'),
	);

	sortFormGroup = this.formBuilder.group({
		sortDirection: [OrderByType.ASC],
		sortBy: [SortByType.LAST_EDITED],
		groupBy: [GroupByOption.NONE],
	});

	private valueCallBacks: ((sortConfig: ProjectSortConfig) => any)[] = [];
	private readonly destroy$ = new Subject();

	ngOnInit(): void {
		this.initWatchChanges();
		this.disableGroupingBasedOnLimit();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	get activeSortDirection(): OrderByType {
		return this.sortFormGroup.get('sortDirection').value;
	}

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly trackingService: TrackingService,
		private readonly store: Store,
	) {}

	writeValue(projectSortConfig: ProjectSortConfig): void {
		this.sortFormGroup.setValue(projectSortConfig);
	}

	registerOnChange(fn: any): void {
		this.valueCallBacks.push(fn);
	}

	registerOnTouched(): void {
		noop();
	}

	setSortDirection(direction: OrderByType): void {
		this.sortFormGroup.get('sortDirection').setValue(direction);
	}

	private initWatchChanges(): void {
		this.sortFormGroup.valueChanges
			.pipe(startWith(this.sortFormGroup.value as object), pairwise(), takeUntil(this.destroy$))
			.subscribe(([oldValue, newValue]) => {
				this.valueCallBacks.forEach((callBack) => callBack(newValue));
				if (!this.isMenuOpen) {
					return;
				}
				if (oldValue.groupBy !== newValue.groupBy) {
					this.emitGroupingChangedEvent(newValue.groupBy ?? GroupByOption.NONE);
				}
				if (oldValue.sortBy !== newValue.sortBy) {
					this.emitSortingChangeEvent(newValue.sortBy);
				}
			});
	}

	private emitGroupingChangedEvent(groupingType: string): void {
		this.trackingService.trackEvent(
			new ProjectsProjectGroupingUpdatedEventBuilder({
				groupingType: groupingType.toLowerCase(),
			}),
		);
	}

	private emitSortingChangeEvent(sortType: string): void {
		this.trackingService.trackEvent(
			new ProjectsProjectSortUpdatedEventBuilder({
				sortType: sortType.toLowerCase(),
			}),
		);
	}

	getSortDirectionString(sortDirection: OrderByType): string {
		if (sortDirection === OrderByType.ASC) {
			return 'project.sort.ascending';
		} else {
			return 'project.sort.descending';
		}
	}

	getSortDirectionIconName(sortDirection: OrderByType): string {
		if (sortDirection === OrderByType.ASC) {
			return 'sort-ascending';
		} else {
			return 'sort-descending';
		}
	}

	async openGroupingPaywallIfNoRights(): Promise<void> {
		if (!(await this.userHasGroupingProfileLimit$.pipe(take(1)).toPromise())) {
			this.openGroupingPaywall.emit();
		}
	}

	disableGroupingBasedOnLimit(): void {
		this.userHasGroupingProfileLimit$.pipe(takeUntil(this.destroy$)).subscribe((hasLimit) => {
			if (hasLimit) {
				this.sortFormGroup.get('groupBy').enable();
			} else {
				this.sortFormGroup.get('groupBy').disable();
			}
		});
	}
}
