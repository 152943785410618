import { Injectable } from '@angular/core';
import {
	ListNotificationResponseData,
	Notification,
	NotificationContinuation,
	NotificationFilter,
	NotificationsConnector,
} from '@injectables/connectors/notifications.connector';
import { firstValueFrom, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TasksConnector } from '@craftnote/shared-injectables';
import { Task } from 'domain-entities';
import { NotificationType } from 'domain-entities/dist/entities/NotificationType';
import { NavigationExtras, Router } from '@angular/router';
import { ProjectConnector } from '@shared/firebase/connectors/firestore/collections/project/project.connector';
import { NotificationsCenterItemOpenedEventBuilder } from '@generated/events/NotificationsEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import { first, last } from 'lodash';
import { isOwnerOfCompany } from '@store/selectors/app.selectors';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { DeletedProjectConnector } from '@shared/firebase/connectors/firestore/collections/project/deleted-project.connector';

type ProjectStatus = 'active' | 'archived' | 'deleted' | 'permissionDenied';

@Injectable({ providedIn: 'root' })
export class NotificationCenterService {
	notificationUnreadCount = '';

	constructor(
		private readonly notificationsConnector: NotificationsConnector,
		private readonly tasksConnector: TasksConnector,
		private readonly projectConnector: ProjectConnector,
		private readonly router: Router,
		private readonly trackingService: TrackingService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
		private readonly deletedProjectConnector: DeletedProjectConnector,
		private readonly store: Store<AppState>,
	) {}

	updateNotificationUnreadCount(notificationResponseData: ListNotificationResponseData): void {
		this.notificationUnreadCount = `${
			notificationResponseData.counts.unread > 9
				? '9+'
				: notificationResponseData.counts.unread === 0
				? ''
				: notificationResponseData.counts.unread
		}`;
	}

	fetchNotifications(
		notificationContinuation: NotificationContinuation,
		filters: NotificationFilter,
	): Promise<ListNotificationResponseData> {
		return firstValueFrom(
			this.notificationsConnector
				.getNotifications(notificationContinuation, filters)
				.pipe(tap((response) => this.updateNotificationUnreadCount(response))),
		);
	}

	async navigate(notification: Notification): Promise<void> {
		let nextRoute: { commands: any[]; extras?: NavigationExtras } = { commands: [] };
		switch (notification.type) {
			case NotificationType.FILE_CREATED:
			case NotificationType.FILE_CREATED_GROUPED:
				nextRoute = await this.getFileNavigation(notification.resourcePath);
				break;
			case NotificationType.CHAT_NEW_MESSAGE_GROUPED:
			case NotificationType.CHAT_NEW_MESSAGE:
				nextRoute = await this.getChatNavigation(notification.resourcePath);
				break;
			case NotificationType.FOLDER_ARCHIVED:
			case NotificationType.FOLDER_UNARCHIVED:
			case NotificationType.FOLDER_DELETED:
			case NotificationType.FOLDER_RESTORED:
			case NotificationType.PROJECT_ARCHIVED:
			case NotificationType.PROJECT_UNARCHIVED:
			case NotificationType.PROJECT_DELETED:
			case NotificationType.PROJECT_RESTORED:
			case NotificationType.PROJECT_INVITATION_CREATED:
				nextRoute = await this.getProjectNavigation(notification.resourcePath);
				break;
			case NotificationType.TASK_CREATED:
			case NotificationType.TASK_ASSIGNED:
			case NotificationType.TASK_DONE:
				nextRoute = await this.getTaskNavigation(notification.resourcePath);
				break;
		}
		this.trackingService.trackEvent(
			new NotificationsCenterItemOpenedEventBuilder({ notificationType: notification.type }),
		);
		await this.router.navigate(nextRoute.commands, nextRoute?.extras);
	}

	markNotificationsAsRead(notifications: Notification[]): void {
		const notificationIds = notifications.map(({ id }) => id);
		firstValueFrom(
			this.notificationsConnector.markNotificationsAsRead({
				notificationIds,
				markAs: 'read',
			}),
		);
	}

	markAllNotificationsAsRead(): void {
		firstValueFrom(
			this.notificationsConnector.markNotificationsAsRead({
				markAs: 'read',
				filter: {
					time: {},
				},
			}),
		);
	}

	private async getTaskById(taskId: string): Promise<Task | string> {
		try {
			return firstValueFrom(
				this.tasksConnector
					.getTaskById(taskId)
					.pipe(catchError((_error) => of('permissionDenied'))),
			);
		} catch (e) {
			return 'permissionDenied';
		}
	}

	private openProjectNotFoundDialog(): void {
		this.confirmDialogService
			.open({
				title: this.translateService.instant('work.project-not-found'),
				primaryButtonColor: 'accent',
				showSecondaryButton: false,
				showCrossBtn: false,
				primaryButtonText: this.translateService.instant('button.close'),
			})
			.afterClosed();
	}

	private async getProjectStatusRouteMapping(
		projectId: string,
	): Promise<{ [key in ProjectStatus]: string[] }> {
		const isOwnerOrSupervisor = await firstValueFrom(this.store.select(isOwnerOfCompany));
		return {
			archived: ['archive', projectId],
			deleted: isOwnerOrSupervisor ? ['restorable-projects'] : [],
			permissionDenied: [],
			active: ['projects', projectId],
		};
	}

	private async getProjectStatusById(projectId: string): Promise<ProjectStatus> {
		let projectStatus: ProjectStatus;
		try {
			const project = await this.projectConnector.getProject(projectId);
			projectStatus = project.status.name;
		} catch (e) {
			projectStatus = 'permissionDenied';
		}
		if (projectStatus === 'permissionDenied') {
			try {
				const deletedProject = await this.deletedProjectConnector.getDeletedProject(projectId);
				if (deletedProject) {
					projectStatus = 'deleted';
				}
			} catch (e) {}
		}
		return projectStatus;
	}

	private async getChatNavigation(
		resourcePath: string,
	): Promise<{ commands: any[]; extras?: NavigationExtras }> {
		const splitPath = resourcePath.split('/');
		const projectId = splitPath[0];
		const messageId = splitPath[1];
		const projectStatus = await this.getProjectStatusById(projectId);
		const projectStatusRouteMapping: { [key in ProjectStatus]: string[] } =
			await this.getProjectStatusRouteMapping(projectId);
		const commands = projectStatusRouteMapping[projectStatus];

		if (
			projectStatus === 'permissionDenied' ||
			(projectStatus === 'deleted' && commands.length === 0)
		) {
			this.openProjectNotFoundDialog();
		}

		return {
			commands,
			extras: {
				queryParams: {
					chatMessage: messageId,
				},
			},
		};
	}

	private async getProjectNavigation(
		resourcePath: string,
	): Promise<{ commands: any[]; extras?: NavigationExtras }> {
		const splitPath = resourcePath.split('/');
		const projectId = last(splitPath);
		const projectStatus = await this.getProjectStatusById(projectId);
		const projectStatusRouteMapping: { [key in ProjectStatus]: string[] } =
			await this.getProjectStatusRouteMapping(projectId);
		const commands = projectStatusRouteMapping[projectStatus];

		if (
			projectStatus === 'permissionDenied' ||
			(projectStatus === 'deleted' && commands.length === 0)
		) {
			this.openProjectNotFoundDialog();
		}

		return {
			commands,
		};
	}

	private async getTaskNavigation(
		resourcePath: string,
	): Promise<{ commands: any[]; extras?: NavigationExtras }> {
		const splitPath = resourcePath.split('/');
		const taskId = last(splitPath);
		const projectId = first(splitPath);
		const task = await this.getTaskById(taskId);
		const projectStatus = await this.getProjectStatusById(projectId);

		if (projectStatus === 'permissionDenied' || projectStatus === 'deleted') {
			this.openProjectNotFoundDialog();
		}

		if (
			task !== 'permissionDenied' &&
			['active', 'archived'].includes(projectStatus) &&
			!(typeof task === 'string')
		) {
			return {
				commands: [
					projectStatus === 'active' ? 'projects' : 'archive',
					projectId,
					'tasks',
					taskId,
					'edit',
				],
				extras: {
					queryParams: { filter: task.done ? 'completed' : 'open' },
				},
			};
		} else {
			return {
				commands: ['projects', projectId, 'tasks', taskId, 'edit'],
			};
		}
	}

	private async getFileNavigation(
		resourcePath: string,
	): Promise<{ commands: any[]; extras?: NavigationExtras }> {
		const splitPath = resourcePath.split('/');
		const fileFolderId = last(splitPath);
		const projectId = first(splitPath);
		const projectStatus = await this.getProjectStatusById(projectId);

		if (projectStatus === 'permissionDenied' || projectStatus === 'deleted') {
			this.openProjectNotFoundDialog();
		}

		return {
			commands: [
				projectStatus === 'active' ? 'projects' : 'archive',
				projectId,
				'files',
				fileFolderId,
			],
		};
	}
}
