import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '@env/environment';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FirebaseStorageConnector } from '@shared/firebase/connectors/storage/firebase-storage.connector';

@Injectable({ providedIn: 'root' })
export class FirebaseStatusService {
	constructor(
		private afStore: AngularFirestore,
		private afDatabase: AngularFireDatabase,
		private http: HttpClient,
		private storageConnector: FirebaseStorageConnector,
	) {}

	public checkFirestoreConnectivity(): Observable<void> {
		return this.afStore
			.doc('/connectivity/connection')
			.get()
			.pipe(
				map((doc) => {
					if (!doc.exists) {
						throw new Error('Connectivity document does not exist');
					}
					const data = doc.data() as { status: string };
					if (data?.status !== 'ok') {
						throw new Error(`Invalid status value: ${data?.status}`);
					}
				}),
				catchError((error) => {
					let errorMessage: string;
					if (error.code === 'permission-denied') {
						errorMessage = 'Permission denied - please check Firestore rules';
					} else if (error.code === 'not-found') {
						errorMessage = 'Connectivity document not found';
					} else {
						errorMessage = error.message || 'Connection failed';
					}
					return throwError(() => new Error(errorMessage));
				}),
			);
	}

	public checkDatabaseConnectivity(): Observable<any> {
		return this.afDatabase.object<{ status: string }>('/connectivity/').valueChanges();
	}

	public checkCloudFunctionsConnectivity(): Observable<any> {
		return this.http.get(`${environment.baseUrl}api/health`, {
			observe: 'response',
			responseType: 'text',
		});
	}

	public checkStorageConnectivity(): Observable<void> {
		return from(
			this.storageConnector
				.getDownloadUrl('connectivity-test.txt')
				.then((url) => fetch(url))
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP ${response.status}: ${response.statusText}`);
					}
				}),
		);
	}

	public getSpeedTestDownloadUrl(): Promise<string> {
		return this.storageConnector.getDownloadUrl('speedtest-100mb.bin');
	}
}
