import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpInvoicesComponent } from './op-invoices.component';

@NgModule({
	declarations: [OpInvoicesComponent],
	imports: [
		CommonModule
	],
	exports: [
		OpInvoicesComponent
	],
})
export class OpInvoicesModule { }
