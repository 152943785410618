import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { selectCompanyId } from '@store/selectors/app.selectors';
import { firstValueFrom } from 'rxjs';
import {
	FeaturesConnector,
	FeatureType,
} from '@shared/firebase/connectors/firestore/collections/one-platform-orders/features.connector';

@Component({
	selector: 'app-features-activation',
	template: '',
	styles: [],
})
export class FeaturesActivationComponent {
	constructor(
		private route: ActivatedRoute,
		private readonly connector: FeaturesConnector,
		private readonly store: Store<AppState>,
		private readonly router: Router,
	) {
		void this.storeAndRedirect();
	}

	async storeAndRedirect(): Promise<void> {
		const params = await firstValueFrom(this.route.queryParams);
		const feature = params['feature'];
		const enabledParam = params['enabled'];

		if (!feature) {
			await this.router.navigate(['/']);
			return;
		}
		if (enabledParam !== 'true' && enabledParam !== 'false') {
			await this.router.navigate(['/']);
			return;
		}
		const isEnabled = enabledParam === 'true';

		if (feature !== FeatureType.ORDERS) {
			await this.router.navigate(['/']);
			return;
		}

		const companyId$ = this.store.select(selectCompanyId);
		const companyId = await firstValueFrom(companyId$);

		await this.connector.updateOrCreateFeature(companyId, feature, isEnabled);
		await this.router.navigate(['/']);
	}
}
