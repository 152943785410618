export enum FilterDirection {
	ASCENDING = 0,
	DESCENDING,
}

export enum FilterType {
	LAST_EDITED = 0,
	NAME,
	CREATION_DATE,
	ALPHABETICAL,
	ORDER_NUMBER,
}
