/* eslint-disable no-console */
import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ENVIRONMENT, Environment} from '@shared/tokens/environment';
import {selectCompanyId} from '@store/selectors/app.selectors';
import {AppState} from '@store/state/app.state';
import {Subject, takeUntil} from 'rxjs';
import {MfeHelperService} from '@injectables/services/mfe-helper/mfe-helper.service';

@Component({
	selector: 'app-op-bids',
	template: `
		<div #container>
			<div *ngIf="activeView === 'list'" #listContainer></div>
			<div *ngIf="activeView === 'detail'" #detailContainer></div>
		</div>
	`,
})
export class OpBidsComponent implements OnInit, OnDestroy, AfterViewInit {
	private destroy$ = new Subject<void>();
	activeView: 'list' | 'detail' = 'list';

	language: string;

	@ViewChild('listContainer', { static: false }) listContainer: ElementRef;
	@ViewChild('detailContainer', { static: false }) detailContainer: ElementRef;

	private opBidListElement: HTMLElement;
	private opBidDetailElement: HTMLElement;

	companyId$ = this.store.select(selectCompanyId);

	companyId: string;
	bidId: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private renderer: Renderer2,
		private store: Store<AppState>,
		private readonly mfeHelperService: MfeHelperService,
		@Inject(ENVIRONMENT) private environment: Environment,
	) {
		void this.loadWebComponents();
	}

	ngOnInit(): void {
		this.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => {
			this.companyId = companyId;
		});
		this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			this.bidId = params['bidId'];
			this.activeView = params['bidId'] || params['createBid'] ? 'detail' : 'list';
			console.log('Active view:', this.activeView);
		});
	}

	ngAfterViewInit(): void {
		this.createWebComponents();
		this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(() => {
			// The setTimeout ensures this runs after Angular's change detection cycle is complete
			// Without it, we may get ExpressionChangedAfterItHasBeenCheckedError when modifying the DOM
			// since we're in a subscription callback that could trigger during change detection
			setTimeout(() => this.createWebComponents(), 0);
		});
	}

	ngOnDestroy(): void {
		// Clean up elements
		if (this.opBidListElement && this.listContainer) {
			this.renderer.removeChild(this.listContainer.nativeElement, this.opBidListElement);
			this.opBidListElement = null;
		}
		if (this.opBidDetailElement && this.detailContainer) {
			this.renderer.removeChild(this.detailContainer.nativeElement, this.opBidDetailElement);
			this.opBidDetailElement = null;
		}

		this.destroy$.next();
		this.destroy$.complete();
	}

	private async loadWebComponents(): Promise<void> {
		try {
			await Promise.all([
				this.loadScript(this.environment.frontendComponents.scripts.opBidList),
				this.loadScript(this.environment.frontendComponents.scripts.opBidDetail),
			]);

			console.log('Web components loaded successfully');
		} catch (error) {
			console.error('Error loading web components:', error);
		}
	}

	private loadScript(url: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script');
			script.src = url;
			script.type = 'module';
			script.onload = () => resolve();
			script.onerror = (error) => reject(error);
			document.body.appendChild(script);
		});
	}

	private createWebComponents(): void {
		this.createListComponent();
		this.createDetailComponent();
	}

	private createListComponent(): void {
		if (!this.listContainer) {
			return;
		}
		if (this.opBidListElement) {
			this.renderer.removeChild(this.listContainer.nativeElement, this.opBidListElement);
			this.opBidListElement = null;
		}
		this.opBidListElement = this.renderer.createElement('op-bid-list');
		this.renderer.setAttribute(this.opBidListElement, 'company-id', this.companyId);
		this.renderer.setAttribute(
			this.opBidListElement,
			'language',
			this.mfeHelperService.getLanguage(),
		);
		this.renderer.listen(this.opBidListElement, 'bidCreateButtonClicked', (event) =>
			this.handleCreateBidButtonClicked(event),
		);
		this.renderer.listen(this.opBidListElement, 'bidDetailClicked', (event) =>
			this.handleViewBidDetailClicked(event),
		);
		this.renderer.appendChild(this.listContainer.nativeElement, this.opBidListElement);
	}

	private handleCreateBidButtonClicked(event: CustomEvent): void {
		console.log('Create bid button clicked:', event);
		void this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { createBid: 'undefined' },
			queryParamsHandling: 'merge',
		});
	}

	private handleViewBidDetailClicked(event: CustomEvent): void {
		this.bidId = event.detail.id;
		void this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { bidId: event.detail.id },
			queryParamsHandling: 'merge',
		});
	}

	private createDetailComponent(): void {
		if (!this.detailContainer) {
			return;
		}
		if (this.opBidDetailElement) {
			this.renderer.removeChild(this.detailContainer.nativeElement, this.opBidDetailElement);
			this.opBidDetailElement = null;
		}

		this.opBidDetailElement = this.renderer.createElement('op-bid-detail');
		this.renderer.setAttribute(this.opBidDetailElement, 'company-id', this.companyId);
		this.renderer.setAttribute(
			this.opBidDetailElement,
			'language',
			this.mfeHelperService.getLanguage(),
		);
		if (this.bidId) {
			this.renderer.setAttribute(this.opBidDetailElement, 'bid-id', this.bidId);
		}

		this.renderer.listen(this.opBidDetailElement, 'bidDetailFormSaveBtnClicked', (event) =>
			this.handleBidSave(event),
		);
		this.renderer.listen(this.opBidDetailElement, 'bidDetailFormBackBtnClicked', (event) =>
			this.handleCancelBid(event),
		);
		this.renderer.appendChild(this.detailContainer.nativeElement, this.opBidDetailElement);
	}

	handleBidSave(event: CustomEvent): void {
		console.log('Detail event:', event.detail);
	}

	handleCancelBid(event: CustomEvent): void {
		console.log('Cancel bid button clicked:', event);
		this.bidId = null;
		void this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {},
		});
	}
}
