import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { firstValueFrom, Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ProjectService } from '@injectables/services/project/project.service';
import { Company } from '@shared/models/company.model';
import { Contact } from '@shared/models/contact.model';
import { Member, Project, Task } from 'domain-entities';
import { ProjectType } from '@shared/models/project.type';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { FILE_TYPE, FileDocument, getMemberFullName } from '@craftnote/shared-utils';
import { getMembersOfProject } from '@shared/functions/project/project.functions';
import moment from 'moment';
import { AlertService } from '@injectables/services/alert/alert.service';
import { Router } from '@angular/router';
import { TasksService } from '@injectables/services/tasks/tasks.service';
import { ThemeService } from '@injectables/services/theme.service';
import { selectIsMemberHasProjectInvitationsRemaining } from '@store/selectors/profile-limits.selectors';
import { isOwnerOfCompany } from '@store/selectors/app.selectors';
import { LinkService } from '@injectables/services/link/link.service';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { keyBy } from 'lodash';

@Component({
	selector: 'app-project-duplicate-dialog',
	templateUrl: './project-duplicate-dialog.component.html',
})
export class ProjectDuplicateDialogComponent implements OnInit, OnDestroy {
	theme: number;

	project: Project;
	company: Company;
	parentProject: Project;
	shouldCopyMembers = true;

	themeSubscription: Subscription;
	private projectParentSubscription: Subscription;
	shouldCopyFolders = true;
	shouldDuplicateTasks = true;
	isLoading = false;

	ProjectType = ProjectType;

	constructor(
		private readonly dialogRef: MatDialogRef<ProjectDuplicateDialogComponent>,
		private readonly projectService: ProjectService,
		private readonly authService: AuthService,
		private readonly fileService: FileExplorerService,
		private readonly translate: TranslateService,
		private readonly tasksService: TasksService,
		private readonly themeService: ThemeService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly alertService: AlertService,
		private readonly router: Router,
		private readonly store: Store<AppState>,
		private readonly linkService: LinkService,
		@Inject(MAT_DIALOG_DATA) public readonly data: any,
	) {}

	ngOnInit(): void {
		this.project = this.data.project;
		this.company = this.data.company;
		this.initTheme();
		this.loadProject(this.project);
	}

	async initTheme(): Promise<void> {
		this.theme = await this.themeService.getTheme();
		this.themeSubscription = this.themeService.getThemeAsync().subscribe((theme) => {
			this.theme = theme;
		});
	}

	loadProject(project: Project): void {
		this.projectParentSubscription = this.projectService
			.getProject(project.parentProject)
			.pipe(take(1))
			.subscribe((projectData) => {
				this.parentProject = projectData;
			});
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		if (this.themeSubscription) {
			this.themeSubscription.unsubscribe();
		}
	}

	getTasksFromOldProject(project: Project): Promise<Task[]> {
		return this.tasksService.getTasksByProjectId(project.id).pipe(take(1)).toPromise();
	}

	private async getProjectsMembersWithNoInvitationsRemaining(members: Member[]): Promise<Member[]> {
		const newMembers = await Promise.all(
			Object.values(members).map((member) =>
				firstValueFrom(
					this.store.select(selectIsMemberHasProjectInvitationsRemaining(member.id)).pipe(
						map((isMemberHasProjectInvitationsRemaining) => ({
							member,
							isMemberHasProjectInvitationsRemaining,
						})),
					),
				),
			),
		);

		return newMembers
			.filter(
				({ isMemberHasProjectInvitationsRemaining }) =>
					isMemberHasProjectInvitationsRemaining === false,
			)
			.map(({ member }) => member);
	}

	async duplicateFolderOrProject(): Promise<void> {
		this.isLoading = true;

		if (this.projectParentSubscription) {
			this.projectParentSubscription.unsubscribe();
		}

		const uid = this.authService.currentUserId();
		const owner = !this.parentProject
			? this.company.members.filter((x) => x.id === uid)[0]
			: getMembersOfProject(this.parentProject).filter((x) => x.id === uid)[0];
		const date = moment().unix();
		const newProject = this.copyProject(this.project, uid, owner, date);

		const projects = await this.projectService
			.getProjectsByParent(this.project.id)
			.pipe(first())
			.toPromise();
		const projectsToCreate = [];

		projects.forEach((project) => {
			const newProjectItem = this.copyProject(project, uid, owner, date);
			newProjectItem.parentProject = newProject.id;
			projectsToCreate.push([project, newProjectItem, owner]);
		});

		if (this.parentProject) {
			newProject.parentProject = this.parentProject.id;
		}

		const projectsMembersWithNoInvitationsRemaining = (
			await this.getProjectsMembersWithNoInvitationsRemaining([
				...Object.values(newProject.members),
				...projects.flatMap((project) => Object.values(project.members)),
			])
		).map((member) => `<br>${getMemberFullName(member)}`);

		await this.createNewProjectFromExisting(this.project, newProject, owner);

		await Promise.all(
			projectsToCreate.map(([project, newChildProject, owner]) =>
				this.createNewProjectFromExisting(project, newChildProject, owner),
			),
		);

		this.closeDialog();
		await this.router.navigate(['/projects', newProject.id, 'details']);
		this.alertService.showAlert(
			newProject.projectType === ProjectType.FOLDER
				? 'folder.createSuccess'
				: 'project.createSuccess',
		);

		// We will skip the members with no invitations remaining and restore the project for the rest of the members
		if (projectsMembersWithNoInvitationsRemaining.length > 0) {
			const message =
				this.translate.instant(
					'project-duplicate-dialog.duplicate-project-restriction-dialog.message',
				) + `<br>${projectsMembersWithNoInvitationsRemaining}`;
			const openUpgradePage = await firstValueFrom(
				this.confirmDialogService
					.open({
						title: this.translate.instant(
							'project-duplicate-dialog.duplicate-project-restriction-dialog.title',
						),
						message,
						primaryButtonColor: 'accent',
						primaryButtonText: this.translate.instant(
							'project-duplicate-dialog.duplicate-project-restriction-dialog.upgrade-button',
						),
						primaryButtonValue: true,
						showSecondaryButton: false,
					})
					.afterClosed(),
			);
			if (openUpgradePage) {
				const isOwner = await firstValueFrom(this.store.select(isOwnerOfCompany));
				if (isOwner) {
					await this.router.navigate(['settings/subscription/products']);
				} else {
					this.linkService.openLinkInNewTab(this.linkService.pricePage);
				}
			}
		}
	}

	private async removeProjectMembersWithNoInvitationsRemaining(project: Project): Promise<Project> {
		const membersWithNoInvitationsRemaining =
			await this.getProjectsMembersWithNoInvitationsRemaining(Object.values(project.members));
		const membersWithInvitationsRemaining = Object.values(project.members).filter(
			(member) => membersWithNoInvitationsRemaining.indexOf(member) === -1,
		);
		return {
			...project,
			members: keyBy(membersWithInvitationsRemaining, 'email'),
		};
	}

	async createNewProjectFromExisting(
		baseProject: Project,
		targetProject: Project,
		owner: Contact,
	): Promise<void> {
		const project = await this.removeProjectMembersWithNoInvitationsRemaining(targetProject);
		await this.projectService.createProject(project);
		await this.copyFilesToProject(baseProject, project);
		if (this.shouldDuplicateTasks && this.project.parentProject !== ProjectType.FOLDER) {
			await this.setTasksToProject(baseProject, project, owner);
		}
	}

	async setTasksToProject(oldProject: Project, newProject: Project, owner: Contact): Promise<void> {
		const tasksPromise = [];
		const tasks: Task[] = await this.getTasksFromOldProject(oldProject);

		for (const task of tasks) {
			task.assigneeId = null;
			task.id = uuid();
			task.createdTimestamp = moment().unix();
			task.assignee = null;
			task.creatorId = owner.id;
			task.deadlineTimestamp = null;
			task.done = false;
			task.projectId = newProject.id;
			tasksPromise.push(this.tasksService.createTask(task));
		}

		await Promise.all(tasksPromise);
	}

	private async copyFilesToProject(sourceProject: Project, targetProject: Project): Promise<void> {
		if (this.shouldCopyFolders && sourceProject.parentProject !== ProjectType.FOLDER) {
			const files = await this.getFilesFromProject(sourceProject.id);
			files && this.duplicateFiles(files, targetProject.id);
		}
	}

	private duplicateFiles(files: FileDocument[], projectId: string): void {
		if (!files) {
			return;
		}

		const folders: FileDocument[] = files.filter(
			(file: FileDocument) => file.type === FILE_TYPE.FOLDER,
		);

		this.changeFoldersProps(folders, projectId);

		folders.forEach((folder) => {
			this.fileService.createFolder(JSON.parse(JSON.stringify(folder)));
		});
	}

	private changeFoldersProps(folders: FileDocument[], projectId: string): void {
		for (const folder of folders) {
			const oldFolderId = folder.id;
			const newFolderId = uuid();
			folder.id = newFolderId;
			folder.projectId = projectId;
			const matchOldFolders = folders.filter(
				(file) => file.folderId && file.folderId === oldFolderId,
			);

			matchOldFolders.forEach((oldFolder) => {
				oldFolder.folderId = newFolderId;
			});
		}
	}

	copyProject(project: Project, uid: string, owner: Contact, date: number): Project {
		const newProject: Partial<Project> = {};
		const duplicateString = this.translate.instant('global.duplicate.noun');
		newProject.id = uuid();
		newProject.name = project.name + ` (${duplicateString})`;
		newProject.company = this.company.id;
		newProject.creator = uid;
		newProject.creationDate = date;
		newProject.lastEditedDate = date;
		newProject.projectType = project.projectType;
		if (project.colorTag) {
			newProject.colorTag = project.colorTag;
		}
		newProject.city = project.city;
		newProject.country = project.country;
		newProject.street = project.street;
		newProject.zipcode = project.zipcode;
		newProject.contacts = project.contacts ? [...project.contacts] : project.contacts;
		newProject.billingCity = project.billingCity;
		newProject.billingCountry = project.billingCountry;
		newProject.billingEmail = project.billingEmail;
		newProject.billingName = project.billingName;
		newProject.billingStreet = project.billingStreet;
		newProject.billingZipcode = project.billingZipcode;

		if (!this.shouldCopyMembers) {
			newProject.members = { [owner.email]: owner as Member };
		} else {
			newProject.members = { ...project.members };
		}
		return newProject as Project;
	}

	private getFilesFromProject(projectId: string): Promise<FileDocument[]> {
		return this.fileService.getProjectFiles(projectId).pipe(take(1)).toPromise();
	}
}
