import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectCompanyId } from '@store/selectors/app.selectors';
import { firstValueFrom } from 'rxjs';
import { FeaturesConnector } from '@shared/firebase/connectors/firestore/collections/one-platform-orders/features.connector';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class FeaturesGuard {
	constructor(
		private readonly featuresConnector: FeaturesConnector,
		private readonly store: Store<AppState>,
	) { }

	async canMatch(route: ActivatedRouteSnapshot): Promise<boolean> {
		const companyId$ = this.store.select(selectCompanyId);
		const companyId = await firstValueFrom(companyId$);
		const feature = route.data.feature;
		return this.featuresConnector.isActive(companyId, feature);
	}
}
