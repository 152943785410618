import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Member, Project, Task, TaskRepeatType } from 'domain-entities';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MemberFullNamePipe } from '../../../pipes/member-full-name/member-full-name.pipe';
import {
	combineLatest,
	fromEvent,
	Observable,
	of,
	ReplaySubject,
	Subject,
	Subscription,
} from 'rxjs';
import { filter, map, pluck, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { getMembersOfProject } from '@shared/functions/project/project.functions';
import { get, isNil, keyBy, orderBy } from 'lodash';
import { Store } from '@ngrx/store';
import { ProfileLimitKey } from '@shared/models/profile-limit-key.enum';
import { ProfileLimitsService } from '@injectables/services/profile-limits.service';
import { TranslateService } from '@ngx-translate/core';
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import { BasicSnackbarComponent } from '../../notification-snackbar/basic-snackbar/basic-snackbar.component';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { MAT_LEGACY_SELECT_CONFIG as MAT_SELECT_CONFIG } from '@angular/material/legacy-select';
import { selectCompanyProjectsMembers } from '@store/selectors/company-projects.selectors';

interface TaskEditForm {
	id: string;
	title: string;
	assigneeId: string;
	assignee: string;
	deadlineTimestamp: Date;
	repeatType: TaskRepeatType;
	description: string;
	done: boolean; 
}

const timeOffsetMap: { [keys: string]: { amount: DurationInputArg1; unit: DurationInputArg2 } } = {
	[TaskRepeatType.DAILY]: { amount: 1, unit: 'days' },
	[TaskRepeatType.WEEKLY]: { amount: 1, unit: 'weeks' },
	[TaskRepeatType.BIWEEKLY]: { amount: 2, unit: 'weeks' },
	[TaskRepeatType.MONTHLY]: { amount: 1, unit: 'months' },
	[TaskRepeatType.YEARLY]: { amount: 1, unit: 'years' },
};

@Component({
	selector: 'app-task-edit-form',
	templateUrl: './task-edit-form.component.html',
	styleUrls: ['./task-edit-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_SELECT_CONFIG,
			useValue: { overlayPanelClass: ['mat-select-overlay-panel'] },
		},
	],
})
export class TaskEditFormComponent implements OnChanges, OnDestroy, OnInit {
	private destroy$: Subject<boolean> = new Subject();
	@ViewChild('assigneeInput', { static: true })
	assigneeInputRef: ElementRef<HTMLInputElement> = null;
	@Input() task: Task = null;
	@Input() project: Project = null;
	@Output() taskUpdated = new EventEmitter<Partial<Task>>();
	@Output() isFormValid = new EventEmitter<boolean>();
	taskEditForm: UntypedFormGroup = this.formBuilder.group({
		id: [null],
		title: [null, [Validators.required]],
		// Using submit is a bit of a hack because we only want to emit value
		// when new value is selected, not when user types. Else it will dispatch unwanted change events,
		// check onAssigneeIdSelected
		assigneeId: [null, { updateOn: 'submit' }],
		assignee: [null],
		deadlineTimestamp: [null],
		repeatType: [null],
		description: [null],
		done: [null],
	});
	taskUpdated$$ = new Subject<Partial<Task>>();
	taskUpdatedSubscription: Subscription = this.taskUpdated$$
		.pipe(
			takeUntil(this.destroy$),
			filter((_) => this.taskEditForm.valid),
		)
		.subscribe((task) => {
			this.taskUpdated.emit(task);
		});
	project$$: ReplaySubject<Project> = new ReplaySubject(1);
	project$: Observable<Project> = this.project$$.asObservable();
	projectMembersEntities: { [id: string]: Member };
	companyMembersEntities: { [id: string]: Member };
	isRecurringTaskEnabled$: Observable<boolean> = this.profileLimitsService
		.getProfileLimits()
		.pipe(pluck(ProfileLimitKey.RECURRING_TASKS));
	taskRepeatTypes: { value: TaskRepeatType; text: string }[] = [
		{ value: TaskRepeatType.NEVER, text: this.translate.instant('task.recurring.never') },
		{ value: TaskRepeatType.DAILY, text: this.translate.instant('task.recurring.daily') },
		{ value: TaskRepeatType.WEEKLY, text: this.translate.instant('task.recurring.weekly') },
		{ value: TaskRepeatType.BIWEEKLY, text: this.translate.instant('task.recurring.biweekly') },
		{ value: TaskRepeatType.MONTHLY, text: this.translate.instant('task.recurring.monthly') },
		{ value: TaskRepeatType.YEARLY, text: this.translate.instant('task.recurring.yearly') },
	];
	assigneesAutoCompleteDisplayWithBound = this.assigneesAutoCompleteDisplayWith.bind(this);
	projectMembersAcceptedFiltered$ = this.projectMembersFiltered();
	taskEditFormLastValue: TaskEditForm = null;

	constructor(
		private readonly formBuilder: UntypedFormBuilder,
		private readonly memberFullNamePipe: MemberFullNamePipe,
		private readonly store: Store,
		private readonly translate: TranslateService,
		private readonly profileLimitsService: ProfileLimitsService,
		private readonly notificationSnackbarService: NotificationSnackbarService,
	) {}

	get assigneeIdControl(): AbstractControl {
		return this.taskEditForm.get('assigneeId');
	}

	get assigneeIdControlValue(): string {
		return this.assigneeIdControl?.value;
	}

	ngOnInit(): void {
		this.initProjectMembers();
		this.initCompanyMembers();
		// Assignee selection
		this.projectMembersAcceptedFiltered$ = this.projectMembersFiltered();
		this.listenTaskEditFormChanges();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.project?.currentValue) {
			this.project$$.next(changes.project.currentValue);
		}
		if (changes.task?.currentValue) {
			const newTask = changes.task.currentValue;

			// Save form values without triggering a full reset
			this.taskEditForm.patchValue(
				{
					id: newTask.id,
					title: newTask.title,
					assigneeId: get(newTask, 'assigneeId', null),
					assignee: get(newTask, 'assignee', null),
					deadlineTimestamp: isNil(newTask.deadlineTimestamp)
						? null
						: moment.unix(newTask.deadlineTimestamp).toDate(),
					repeatType: get(newTask, 'repeatType', null),
					description: get(newTask, 'description', null),
					done: get(newTask, 'done', null),
				},
				{ emitEvent: false },
			);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	listenTaskEditFormChanges(): void {
		this.taskEditForm.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((value: TaskEditForm) => {
				if (this.taskEditFormLastValue?.done === false && value.done === true) {
					this.notificationSnackbarService.show(BasicSnackbarComponent, {
						componentTypes: {
							description: this.translate.instant('task.task-completion-notification'),
							icon: 'done',
						},
						level: 1,
					});
				}

				if (
					this.taskEditFormLastValue?.done === false &&
					value.done === true &&
					value.repeatType &&
					value.repeatType !== TaskRepeatType.NEVER &&
					value.deadlineTimestamp
				) {
					value.deadlineTimestamp = this.calculateNewDeadlineTimestamp(
						value.repeatType,
						this.taskEditFormLastValue.deadlineTimestamp,
					);
					value.done = false;
					this.taskEditForm.patchValue(value, { emitEvent: false });
				}

				this.taskEditFormLastValue = value;
				this.taskUpdated$$.next(this.getTaskFromTaskEditForm(value));
				this.isFormValid.emit(this.taskEditForm.valid);
			});
	}

	// TaskEditForm -> Task
	getTaskFromTaskEditForm(taskFormValue: TaskEditForm): Partial<Task> {
		if (!taskFormValue) {
			return null;
		}

		// Removing the undefined keys
		return {
			id: taskFormValue.id,
			title: taskFormValue.title,
			projectId: this.project.id,
			description: get(taskFormValue, 'description'),
			assigneeId: get(taskFormValue, 'assigneeId'),
			assignee: get(taskFormValue, 'assignee'),
			deadlineTimestamp: isNil(taskFormValue.deadlineTimestamp)
				? null
				: moment(taskFormValue.deadlineTimestamp).unix().valueOf(),
			repeatType: get(taskFormValue, 'repeatType'),
			done: get(taskFormValue, 'done'),
		};
	}

	clearAssigneeId(): void {
		this.taskEditForm.patchValue({
			assignee: null,
			assigneeId: null,
		});
	}

	// This one is a hack
	onAssigneeIdSelected(event: MatAutocompleteSelectedEvent): void {
		const assigneeId = event?.option?.value;
		const assigneeControl = this.taskEditForm.get('assignee');
		const assigneeIdControl = this.taskEditForm.get('assigneeId');
		const assignee = assigneeId ? this.projectMembersEntities[assigneeId] : null;
		// Updating the assigneeId over here because assigneeId is disabled in reactive form using 'submit'
		assigneeIdControl.setValue(assigneeId, { emit: false });
		assigneeControl.setValue(
			assignee ? this.memberFullNamePipe.transform(assignee, 'email', true) : null,
		);
	}

	assigneesAutoCompleteDisplayWith(memberId: string): string {
		if (!memberId) {
			return memberId;
		}

		let member = this.projectMembersEntities[memberId];

		// We are searching from company members because, there is a case when member is removed from project
		if (!member) {
			member = this.companyMembersEntities[memberId];
		}

		if (!member) {
			return this.task?.assignee;
		}
		return this.memberFullNamePipe.transform(member, 'email', true);
	}

	calculateNewDeadlineTimestamp(repeatType: TaskRepeatType, lastDeadlineTimestamp: Date): Date {
		const { amount = 0, unit = 'days' } = timeOffsetMap[repeatType];
		return moment(lastDeadlineTimestamp).add(amount, unit).toDate();
	}

	private initProjectMembers(): Subscription {
		return this.project$
			.pipe(
				takeUntil(this.destroy$),
				map(getMembersOfProject),
				map((members) => {
					const projectMembers = members.filter((member) => member.id);
					this.projectMembersEntities = keyBy(projectMembers, 'id');
					return projectMembers;
				}),
			)
			.subscribe();
	}

	private initCompanyMembers(): Subscription {
		return this.store
			.select(selectCompanyProjectsMembers)
			.pipe(filter((companyMembers) => !!companyMembers))
			.pipe(
				takeUntil(this.destroy$),
				map((members) => Object.values(members)),
				map((members) => {
					const companyMembers = members.filter((member) => member.id);
					this.companyMembersEntities = keyBy(companyMembers, 'id');
					return companyMembers;
				}),
			)
			.subscribe();
	}

	private projectMembersFiltered(): Observable<{ text: string; id: string }[]> {
		return this.project$.pipe(
			map(getMembersOfProject),
			map((members) => members.filter((member) => member.id)),
			switchMap((members) => {
				const membersStream = of(members);
				const userInputStream = fromEvent(this.assigneeInputRef.nativeElement, 'keyup').pipe(
					pluck('target', 'value'),
					startWith(''),
				);
				return combineLatest([membersStream, userInputStream]);
			}),
			map(([members, searchText = '']: [Member[], string]) =>
				members
					.map((member) => ({
						text: this.memberFullNamePipe.transform(member, 'email'),
						id: member.id,
					}))
					.filter((member) => member.text.includes(searchText)),
			),
			map((members) => orderBy(members, (member) => member.text.toLowerCase())),
		);
	}
}
