import { Component, Inject, OnDestroy } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import {
	isOwnerOfCompany,
	selectCompanyId,
	selectCompanyMembers,
	selectCompanyMembersAcceptedEntities,
	selectCompanyMembersArray,
	selectUserEmail,
	selectUserId,
} from '@store/selectors/app.selectors';
import { debounceTime, filter, map, startWith, switchMapTo, take, tap } from 'rxjs/operators';
import { Invitation, InviteState, Member, Project, ProjectType } from 'domain-entities';
import { BehaviorSubject, combineLatest, firstValueFrom, noop, Observable } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ProjectPrefillConfirmDialogData } from '../project-prefill-dialog/project-prefill-dialog.component';
import moment from 'moment';
import { addMemberToProjectFactory } from '@shared/firebase/project/project-update.functions';
import { ProjectService } from '@injectables/services/project/project.service';
import { InviteService } from '@injectables/services/invite/invite.service';
import { v4 as uuid } from 'uuid';
import { PerformanceTrace } from '@shared/constants/performace-trace';
import { PerformanceTraceService } from '@injectables/services/performance-trace.service';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import {
	selectInvitations,
	selectInvitationsLoadingState,
} from '@store/selectors/invitations.selectors';
import { StoreLoadingStatus } from '@store/entities/store-loading-status';
import { subscribeToInvitationsChanges } from '@store/actions/invitations.actions';
import { keyBy, sortBy, values } from 'lodash';
import { InvitationService } from '@injectables/services/invitation/invitation.service';
import { CompanyMemberOperation } from '@modules/shared/components/add-edit-company-employee/add-edit-company-employee.component';
import { ActivatedRoute, Router } from '@angular/router';
import { selectProject } from '@store/selectors/projects.selectors';
import {
	selectCurrentUserProjectInvitationsRemaining,
	selectIsMemberHasProjectInvitationsRemaining,
} from '@store/selectors/profile-limits.selectors';
import { NoInvitationsRemainingService } from '@injectables/services/no-invitations-remaining.service';

export interface ProjectCreationDialogData {
	projectType?: ProjectType;
	prefillOptions?: ProjectPrefillConfirmDialogData;
	parentProject?: Project;
}

@Component({
	selector: 'app-project-create-dialog',
	templateUrl: './project-create-dialog.component.html',
	styleUrls: ['./project-create-dialog.component.scss'],
})
export class ProjectCreateDialogComponent implements OnDestroy {
	ProjectType = ProjectType;
	CompanyMemberOperation = CompanyMemberOperation;
	InviteState = InviteState;

	POSITIVE_INFINITY = Number.POSITIVE_INFINITY;

	currentUserProjectInvitationsRemaining$ = this.store.select(
		selectCurrentUserProjectInvitationsRemaining,
	);

	members$ = new BehaviorSubject<{ [email: string]: { member: Member; selected: boolean } }>({});
	membersList$ = this.members$.pipe(map((members) => values(members)));
	membersSelected$ = this.membersList$.pipe(
		map((members) => members.filter((member) => member.selected).map((member) => member.member)),
	);

	projectForm = this.fb.group({
		name: ['', [Validators.required]],
	});
	searchForm = this.fb.control('');

	currentUserId$ = this.store.select(selectUserId);
	currentUserEmail$ = this.store.select(selectUserEmail);
	isOwner$ = this.store.select(isOwnerOfCompany);
	companyMembers$ = combineLatest([
		this.currentUserEmail$,
		this.store.select(selectCompanyMembersArray),
	]).pipe(
		map(([email, companyMembers]) =>
			Object.values(companyMembers).filter((member) => member.email !== email)
		),
	);
	filteredCompanyMembers$: Observable<{ member: Member; selected: boolean }[]> = combineLatest([
		this.membersList$,
		this.searchForm.valueChanges.pipe(debounceTime(100), startWith('')),
		this.store.select(selectCompanyMembers),
	]).pipe(
		map(([members, searchTxt, companyMembers]) => {
			const filteredMembers = members.filter((member) =>
				this.companyMemberFilterPredicate(member.member, searchTxt),
			);
			/**
			 * Members are sorted in the following order
			 * Accepted company members
			 * Non company members (i.e. externals taken over from a parent project)
			 * Company members with an invite state other than ACCEPTED
			 */
			const sortedFilteredMembers = sortBy(filteredMembers, (member) => {
				let sortOrder: number;
				if (
					companyMembers[member.member.email] &&
					member.member.inviteState === InviteState.ACCEPTED
				) {
					sortOrder = 1;
				} else if (!companyMembers[member.member.email]) {
					sortOrder = 2;
				} else {
					sortOrder = 3;
				}

				return [sortOrder, member.member.lastname, member.member.name]; //this pissed me of so much so I had to change the sort order to lastname
			});

			// Update the currentFilteredMembers
			this.updateFilteredMembers(sortedFilteredMembers);

			return sortedFilteredMembers;
		}),
	);

	isSavingInProgress = false;
	inviteUserViewVisible = false;
	buttonsIdIsLoading: { [id: string]: boolean } = {};
	invitationsEntity$: Observable<{ [email: string]: Invitation }> = this.store
		.select(selectInvitationsLoadingState)
		.pipe(
			filter((status) => status === StoreLoadingStatus.LOADED),
			take(1),
			switchMapTo(this.store.select(selectInvitations)),
			map((invitation) => keyBy(invitation, (invite) => invite.inviteeEmail)),
		);

	allMembersSelected = false;
	someMembersSelected = false;

	// Add this new property to store the current filtered members
	private currentFilteredMembers: { member: Member; selected: boolean }[] = [];

	constructor(
		public readonly dialogRef: MatDialogRef<ProjectCreateDialogComponent>,
		private readonly store: Store<AppState>,
		private readonly fb: UntypedFormBuilder,
		private readonly dialog: MatDialog,
		private readonly projectService: ProjectService,
		private readonly performanceTraceService: PerformanceTraceService,
		private readonly inviteService: InviteService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translate: TranslateService,
		private readonly invitationService: InvitationService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly noInvitationsRemainingService: NoInvitationsRemainingService,
		@Inject(MAT_DIALOG_DATA) public data: ProjectCreationDialogData,
	) {
		this.store.dispatch(subscribeToInvitationsChanges());
		void this.initProjectDialogData();
		this.updateUrlFragment();
	}

	get dialogTitle(): string {
		if (this.inviteUserViewVisible) {
			return 'companyMembers.invite.dialog.title.addEmployee';
		}
		if (this.data.projectType === ProjectType.FOLDER) {
			return 'project.create-dialog.project-folder-title';
		}
		if (this.data.projectType === ProjectType.PROJECT) {
			return 'project.create-dialog.project-title';
		}
		return '';
	}

	selectIsMemberHasProjectInvitationsRemaining$ = (memberWithSelection: {
		member: Member;
		selected: boolean;
	}): Observable<boolean> =>
		this.store
			.select(selectIsMemberHasProjectInvitationsRemaining(memberWithSelection.member.id))
			.pipe(
				tap((hasRemaining) => {
					if (!hasRemaining && memberWithSelection.member.id) {
						memberWithSelection.selected = false;
					}
				}),
				map((hasRemaining) => {
					if (this.data.projectType === ProjectType.FOLDER) {
						return true;
					}
					return hasRemaining;
				}),
			);

	async ngOnDestroy(): Promise<void> {
		await this.router.navigate([], {
			relativeTo: this.activatedRoute,
		});
	}

	async closeModal(): Promise<void> {
		if (
			this.projectForm.value?.name?.length > 0 ||
			(await firstValueFrom(this.membersSelected$)).length > 0
		) {
			const result = await firstValueFrom(
				this.confirmDialogService
					.open({
						title: this.translate.instant('project.create-confirmation-dialog.body'),
						primaryButtonText: this.translate.instant('project.create-confirmation-dialog.yes-btn'),
						secondaryButtonText: this.translate.instant(
							'project.create-confirmation-dialog.no-btn',
						),
						primaryButtonColor: 'warn',
					})
					.afterClosed(),
			);

			if (!result) {
				return;
			}
		}
		this.dialogRef.close(false);
	}

	async onSubmitForm(): Promise<void> {
		const projectId = await this.createProject(this.data.projectType);
		await firstValueFrom(
			this.store.select(selectProject, { projectId: projectId }).pipe(filter(Boolean)),
		);
		this.dialogRef.close(projectId);
	}

	toggleAllMembers(checked: boolean): void {
		const newMembers = { ...this.members$.value };
		// Only toggle members that are currently filtered/visible and have accepted invites
		this.currentFilteredMembers.forEach(filteredMember => {
			if (filteredMember.member.inviteState === InviteState.ACCEPTED) {
				newMembers[filteredMember.member.email].selected = checked;
			}
		});
		this.members$.next(newMembers);
		this.updateSelectAllState();
	}

	private updateFilteredMembers(filteredMembers: { member: Member; selected: boolean }[]): void {
		this.currentFilteredMembers = filteredMembers;
		this.updateSelectAllState();
	}

	private updateSelectAllState(): void {
		const selectedCount = this.currentFilteredMembers.filter(m => m.selected).length;
		const totalCount = this.currentFilteredMembers.length;

		this.allMembersSelected = selectedCount === totalCount && totalCount > 0;
		this.someMembersSelected = selectedCount > 0 && selectedCount < totalCount;
	}

	async toggleMemberSelection(member: Member): Promise<void> {
		if (member.inviteState !== InviteState.ACCEPTED) {
			// Optionally, show a message that only accepted members can be added
			return;
		}
		const isMemberIncluded = this.members$.value[member.email].selected;
		const newMembers = { ...this.members$.value };
		newMembers[member.email] = { member, selected: !isMemberIncluded };
		this.members$.next(newMembers);
		this.updateSelectAllState();
	}

	onResetSearchForm(): void {
		this.searchForm.reset('');
	}

	updateInviteUserView(isVisible: boolean, event: Event): void {
		event?.preventDefault();
		this.inviteUserViewVisible = isVisible;
		if (!isVisible) {
			setTimeout(async () => {
				await this.updateUrlFragment();
			});
		}
	}

	async resendInvitation(member: Member, event: Event, id: string): Promise<void> {
		event?.preventDefault();
		this.buttonsIdIsLoading[id] = true;
		await this.invitationService.handleResendInvitation(member, noop);
		delete this.buttonsIdIsLoading[id];
	}

	async remindInvitation(invitation: Invitation, event: Event, id: string): Promise<void> {
		event?.preventDefault();
		this.buttonsIdIsLoading[id] = true;
		await this.invitationService.handleRemindInvitation(invitation?.id, noop, 'project-creation');
		delete this.buttonsIdIsLoading[id];
	}

	async openNoProjectInvitationsRemainingPaywallDialog(event: Event): Promise<void> {
		if ((await firstValueFrom(this.membersList$)).length === 0) {
			return;
		}
		const isOwner = await firstValueFrom(this.isOwner$);
		event.preventDefault();
		const result =
			await this.noInvitationsRemainingService.openNoProjectInvitationsRemainingPaywallDialog(
				'project-members',
				{
					title: this.translate.instant('project-create-dialog.no-project-remaining.title'),
					message: isOwner
						? this.translate.instant('project-create-dialog.no-project-remaining.owner.message')
						: this.translate.instant(
								'project-create-dialog.no-project-remaining.supervisor.message',
						  ),
					messageList: [
						this.translate.instant('project-create-dialog.no-project-remaining.message-1'),
						this.translate.instant('project-create-dialog.no-project-remaining.message-2'),
						this.translate.instant('project-create-dialog.no-project-remaining.message-3'),
					],
				},
			);
		if (result === 'open-upgrade' && isOwner) {
			this.dialog.closeAll();
		}
	}

	private async updateUrlFragment(): Promise<void> {
		await this.router.navigate([], {
			relativeTo: this.activatedRoute,
			fragment: 'create-project',
		});
	}

	private async initProjectDialogData(): Promise<void> {
		const members: { [email: string]: { member: Member; selected: boolean } } = {};
		for (const companyMember of await firstValueFrom(this.companyMembers$)) {
			members[companyMember.email] = { member: companyMember, selected: false };
		}
		if (this.data.prefillOptions?.invite) {
			Object.values(this.data.parentProject?.members || {}).forEach((member) => {
				if (member.inviteState === InviteState.ACCEPTED) {
					members[member.email] = { member: member, selected: true };
				}
			});
		}
		this.members$.next(members);
	}

	private companyMemberFilterPredicate(member: Member, searchText: string): boolean {
		const text = searchText.toLowerCase();
		return `${member.lastname}, ${member.name}`.toLowerCase().includes(text.toLowerCase());
	}

	private async createProject(projectType: ProjectType): Promise<string> {
		const { parentProject } = this.data;
		const [uid, userEmail, companyMembers, companyId] = await firstValueFrom(
			combineLatest([
				this.currentUserId$,
				this.currentUserEmail$,
				this.store.select(selectCompanyMembersAcceptedEntities),
				this.store.select(selectCompanyId),
			]),
		);
		const projectName = this.projectForm.value.name;
		const members = keyBy(
			(await firstValueFrom(this.membersSelected$)).filter(
				(member) => member.inviteState === InviteState.ACCEPTED
			),
			(value) => value.email
		);
		const date = moment().unix();
		let project: Project = {
			id: uuid(),
			name: projectName,
			projectType,
			company: companyId,
			members,
			creationDate: date,
			lastEditedDate: date,
			colorTag: parentProject?.colorTag ? parentProject?.colorTag : 'COLOR4',
			creator: uid,
			status: {
				name: 'active',
				setAt: date,
				setBy: uid,
			},
		};

		if (parentProject) {
			project.parentProject = parentProject.id;
		}

		if (this.data.prefillOptions) {
			project = this.updateProjectWithPrefilledOptions(
				project,
				parentProject,
				this.data.prefillOptions,
			);
		}

		project = { ...project, ...addMemberToProjectFactory(companyMembers[userEmail])(project) };

		if (projectType === ProjectType.PROJECT) {
			await this.performanceTraceService.start(PerformanceTrace.TRANSACTION_NEW_CHAT_LOADED);
		}

		await this.projectService.createProject(
			project,
			projectType === ProjectType.FOLDER ? 'folder.createSuccess' : 'project.createSuccess',
		);
		await this.inviteService.saveInvites(Object.values(members), project as Project);
		return project.id;
	}

	private updateProjectWithPrefilledOptions(
		baseProject: Project,
		parentProject: Project,
		prefilledOptions: ProjectPrefillConfirmDialogData,
	): Project {
		if (prefilledOptions?.address) {
			baseProject.street = parentProject.street;
			baseProject.zipcode = parentProject.zipcode;
			baseProject.city = parentProject.city;
			baseProject.country = parentProject.country;
		}

		if (prefilledOptions.billing) {
			baseProject.billingName = parentProject.billingName;
			baseProject.billingEmail = parentProject.billingEmail;
			baseProject.billingCountry = parentProject.billingCountry;
			baseProject.billingCity = parentProject.billingCity;
			baseProject.billingZipcode = parentProject.billingZipcode;
			baseProject.billingStreet = parentProject.billingStreet;
		}
		if (prefilledOptions.contacts) {
			baseProject.contacts = parentProject.contacts;
		}

		return baseProject;
	}
}
