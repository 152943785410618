/* eslint-disable no-console */
import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ENVIRONMENT, Environment} from '@shared/tokens/environment';
import {selectCompanyId} from '@store/selectors/app.selectors';
import {AppState} from '@store/state/app.state';
import {Subject, takeUntil} from 'rxjs';

@Component({
	selector: 'app-op-invoices',
	template: `
		<div #container>
			<div *ngIf="activeView === 'list'" #listContainer></div>
			<div *ngIf="activeView === 'detail'" #detailContainer></div>
		</div>
	`,
})
export class OpInvoicesComponent implements OnInit, OnDestroy, AfterViewInit {
	private destroy$ = new Subject<void>();
	activeView: 'list' | 'detail' = 'list';

	language: string;

	@ViewChild('listContainer', { static: false }) listContainer: ElementRef;
	@ViewChild('detailContainer', { static: false }) detailContainer: ElementRef;

	private opInvoiceListElement: HTMLElement;
	private opInvoiceDetailElement: HTMLElement;

	companyId$ = this.store.select(selectCompanyId);
	companyId: string;
	invoiceId: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private renderer: Renderer2,
		private store: Store<AppState>,
		@Inject(ENVIRONMENT) private environment: Environment,
	) {
		void this.loadWebComponents();
	}

	ngOnInit(): void {
		this.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => {
			this.companyId = companyId;
		});
		this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			this.invoiceId = params['invoiceId'];
			this.activeView = params['invoiceId'] || params['createInvoice'] ? 'detail' : 'list';
			console.log('Active view:', this.activeView);
		});
	}

	ngAfterViewInit(): void {
		this.createWebComponents();

		this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(() => {
			// The setTimeout ensures this runs after Angular's change detection cycle is complete
			// Without it, we may get ExpressionChangedAfterItHasBeenCheckedError when modifying the DOM
			// since we're in a subscription callback that could trigger during change detection
			setTimeout(() => this.createWebComponents(), 0);
		});
	}

	ngOnDestroy(): void {
		// Clean up elements
		if (this.opInvoiceListElement && this.listContainer) {
			this.renderer.removeChild(this.listContainer.nativeElement, this.opInvoiceListElement);
			this.opInvoiceListElement = null;
		}
		if (this.opInvoiceDetailElement && this.detailContainer) {
			this.renderer.removeChild(this.detailContainer.nativeElement, this.opInvoiceDetailElement);
			this.opInvoiceDetailElement = null;
		}

		this.destroy$.next();
		this.destroy$.complete();
	}

	private async loadWebComponents(): Promise<void> {
		try {
			// Load both web components using script tag injection
			await Promise.all([
				this.loadScript(this.environment.frontendComponents.scripts.opInvoiceList),
				this.loadScript(this.environment.frontendComponents.scripts.opInvoiceDetail),
			]);

			console.log('Web components loaded successfully');
		} catch (error) {
			console.error('Error loading web components:', error);
		}
	}

	private loadScript(url: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const script = document.createElement('script');
			script.src = url;
			script.type = 'module';
			script.onload = () => resolve();
			script.onerror = (error) => reject(error);
			document.body.appendChild(script);
		});
	}

	// Split createWebComponents into two separate methods for better control
	private createWebComponents(): void {
		this.createListComponent();
		this.createDetailComponent();
	}

	private createListComponent(): void {
		if (!this.listContainer) {
			return;
		}
		if (this.opInvoiceListElement) {
			this.renderer.removeChild(this.listContainer.nativeElement, this.opInvoiceListElement);
			this.opInvoiceListElement = null;
		}

		this.opInvoiceListElement = this.renderer.createElement('op-invoice-list');
		this.renderer.setAttribute(this.opInvoiceListElement, 'company-id', this.companyId);
		this.renderer.listen(this.opInvoiceListElement, 'invoiceCreateButtonClicked', (event) =>
			this.handleCreateInvoiceButtonClicked(event),
		);
		this.renderer.listen(this.opInvoiceListElement, 'invoiceDetailClicked', (event) =>
			this.handleViewInvoiceDetailClicked(event),
		);
		this.renderer.appendChild(this.listContainer.nativeElement, this.opInvoiceListElement);
	}

	private handleCreateInvoiceButtonClicked(event: CustomEvent): void {
		console.log('Create invoice button clicked:', event);
		void this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { createInvoice: 'undefined' },
			queryParamsHandling: 'merge',
		});
	}

	private handleViewInvoiceDetailClicked(event: CustomEvent): void {
		console.log('View invoice button clicked:', event);
		this.invoiceId = event.detail.id;
		void this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { invoiceId: event.detail.id },
			queryParamsHandling: 'merge',
		});
	}

	private createDetailComponent(): void {
		if (!this.detailContainer) {
			return;
		}
		if (this.opInvoiceDetailElement) {
			this.renderer.removeChild(this.detailContainer.nativeElement, this.opInvoiceDetailElement);
			this.opInvoiceDetailElement = null;
		}

		console.log('Creating detail component', this.invoiceId);
		this.opInvoiceDetailElement = this.renderer.createElement('op-invoice-detail');
		this.renderer.setAttribute(this.opInvoiceDetailElement, 'company-id', this.companyId);
		if (this.invoiceId) {
			this.renderer.setAttribute(this.opInvoiceDetailElement, 'invoice-id', this.invoiceId);
		}

		this.renderer.listen(this.opInvoiceDetailElement, 'invoiceDetailFormSubmit', (event) =>
			this.handleInvoiceSave(event),
		);
		this.renderer.listen(this.opInvoiceDetailElement, 'cancelDetailFormClicked', (event) =>
			this.handleCancelInvoice(event),
		);
		this.renderer.appendChild(this.detailContainer.nativeElement, this.opInvoiceDetailElement);
	}

	handleInvoiceSave(event: CustomEvent): void {
		console.log('Detail event:', event.detail);
		alert('Invoice saved');
	}

	handleCancelInvoice(event: CustomEvent): void {
		console.log('Cancel invoice button clicked:', event);
		this.invoiceId = null;
		void this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {},
		});
	}
}
