<div class="connectivity-check">
  <h2>Verbindungsstatus</h2>
  
  <div class="status-grid">
    <div *ngFor="let status of statuses" class="status-item">
      <div class="status-header">
        <span class="status-name">{{ status.name }}</span>
        <span class="status-indicator" [ngClass]="status.status">
          <ng-container [ngSwitch]="status.status">
            <span *ngSwitchCase="'checking'">⏳</span>
            <span *ngSwitchCase="'success'">✅</span>
            <span *ngSwitchCase="'error'">❌</span>
          </ng-container>
        </span>
      </div>
      
      <div *ngIf="status.progress !== undefined" class="status-progress">
        <mat-progress-bar mode="determinate" [value]="status.progress"></mat-progress-bar>
      </div>

      <div *ngIf="status.error" class="status-error">
        {{ status.error }}
      </div>
    </div>
  </div>

  <div class="button-container">
    <button (click)="checkConnectivity()" class="refresh-button">
      Status aktualisieren
    </button>
    <button (click)="startSpeedTest()" class="speed-test-button">
      Geschwindigkeitstest starten (100MB)
    </button>
  </div>

  <div *ngIf="isLoggedIn$ | async" class="project-count">
    <div class="status-header">
      <span class="status-name">Aktive Projekte</span>
      <ng-container *ngIf="projectCountStatus$ | async as status">
        <span class="status-indicator" [ngClass]="status.color">
          {{ projectCount$ | async }}
          <span *ngIf="status.icon" class="status-icon">{{ status.icon }}</span>
        </span>
        <div *ngIf="status.message" class="status-warning">
          {{ status.message }}
        </div>
      </ng-container>
    </div>
  </div>
</div> 