import { Injectable } from '@angular/core';
import { OrderByType, Project, SortByType } from 'domain-entities';
import { findKey, groupBy, isNil, last, maxBy } from 'lodash';
import { selectProjectUnreadsOfActiveProjectsEntities } from '@store/selectors/project-unreads.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';

enum SpecialSearchKey {
	UNREAD = 'unread',
}

interface ProjectSearchContext {
	userId?: string;
	projectStatuses?: { [id: string]: string };
}

type SpecialKeyWithAlias = { [key in SpecialSearchKey]: string[] };

const SPECIAL_SEARCH_KEYS: SpecialKeyWithAlias = {
	[SpecialSearchKey.UNREAD]: ['unread', 'ungelesen'],
};

@Injectable({
	providedIn: 'root',
})
export class ProjectSortAndSearchHelper {
	projectUnreadsOfActiveProjectsEntities = {};

	constructor(private store: Store<AppState>) {
		this.initProjectUnreads();
	}

	sort(
		projects: Project[],
		sortType: SortByType,
		sortDirection: OrderByType,
		allKnownProjects?: Project[],
	): Project[] {
		if (!projects) {
			return projects;
		}

		// Storing the original Projects by id
		const projectsById = projects.reduce<{ [keys: string]: Project }>((acc, project) => {
			acc[project.id] = project;
			return acc;
		}, {});

		// Create temporal Projects with computed values for folder timestamps
		const projectsWithInferredTimestamps = this.generateProjectsWithInferredTimestamps(
			projects,
			allKnownProjects || projects,
		);

		let sortField: keyof Project;
		switch (sortType) {
			case SortByType.ALPHABETICAL:
				sortField = 'name';
				break;
			case SortByType.CREATION_DATE:
				sortField = 'creationDate';
				break;
			case SortByType.LAST_EDITED:
				sortField = 'lastEditedDate';
				break;
			case SortByType.ORDER_NUMBER:
				sortField = 'orderNumber';
				break;
			default:
				sortField = 'name';
		}

		const projectsGroupedByFieldExistence = groupBy(projectsWithInferredTimestamps, (project) =>
			isNil(project[sortField]) || project[sortField] === '' ? 'false' : 'true',
		);

		const topProjectSearchList = projectsGroupedByFieldExistence['true'] || [];
		const bottomSearchList = projectsGroupedByFieldExistence['false'] || [];

		const compareFunctionForExistingValues = this.generateProjectSortFunction(sortField);
		const compareFunctionForName = this.generateProjectSortFunction('name');
		const compareFunctionForId = this.generateProjectSortFunction('id');

		const sortDirectionString = sortDirection.toLowerCase() as 'asc' | 'desc';

		let topPart = this.sortProjects(
			topProjectSearchList,
			[compareFunctionForExistingValues, compareFunctionForName, compareFunctionForId],
			sortDirectionString,
		);
		let bottomPart = this.sortProjects(
			bottomSearchList,
			[compareFunctionForName, compareFunctionForId],
			sortDirectionString,
		);

		// Map projects with inferred timestamps back to original projects
		topPart = topPart.map((project) => projectsById[project.id]);
		bottomPart = bottomPart.map((project) => projectsById[project.id]);

		return [...topPart, ...bottomPart];
	}

	searchProjectFolders(
		projects: Project[],
		searchKey: string,
		context?: ProjectSearchContext,
	): Project[] {
		return searchKey
			? projects.filter((project) => this.defaultSearchCondition(project, searchKey, context))
			: projects;
	}

	defaultSearchCondition(
		project: Project,
		searchKey: string,
		context?: ProjectSearchContext,
	): boolean {
		if (!searchKey) {
			return true;
		}
		return (
			this.projectFieldMatchSearchCondition(project, searchKey) ||
			this.specialKeySearchCondition(searchKey, project, context) ||
			this.projectStatusSearchCondition(searchKey, project, context)
		);
	}

	private projectFieldMatchSearchCondition(project: Project, searchKey: string): boolean {
		return [
			'name',
			'city',
			'zipcode',
			'street',
			'country',
			'clientName',
			'orderNumber',
			'billingName',
		].some((field) => this.fieldIncludesString(project, field, searchKey));
	}

	getInferredLastEditedTimestampByProjectId(projects: Project[]): { [keys: string]: number } {
		const inferredProjects = this.generateProjectsWithInferredTimestamps(projects, projects);
		return inferredProjects.reduce<{ [keys: string]: number }>((acc, project) => {
			acc[project.id] = project.lastEditedDate;
			return acc;
		}, {});
	}

	private fieldIncludesString(obj: object, fieldName: string, value: string): boolean {
		return obj[fieldName] && (obj[fieldName] as string).toLowerCase().includes(value.toLowerCase());
	}

	private generateProjectSortFunction(sortField): (projectA: Project) => string | number {
		return (projectA: Project) => {
			const sortFieldValue = projectA[sortField];
			if (typeof sortFieldValue === 'string') {
				return sortFieldValue.toLowerCase().trim();
			}
			return sortFieldValue;
		};
	}

	private generateProjectsWithInferredTimestamps(
		projectsToSort: Project[],
		allKnownProjects: Project[],
	): Project[] {
		const childrenByParent = groupBy(allKnownProjects, (project) => project.parentProject);
		const result: Project[] = [];

		for (const project of projectsToSort) {
			const childrenOfProject = childrenByParent[project.id];
			if (!childrenOfProject) {
				result.push(project);
				continue;
			}
			const latestLastEditedDateOfChildren =
				maxBy(childrenOfProject, (innerProject) => innerProject.lastEditedDate)?.lastEditedDate ||
				0;

			const lastEditedDate = Math.max(project.lastEditedDate, latestLastEditedDateOfChildren);

			result.push({ ...project, lastEditedDate });
		}

		return result;
	}

	private specialKeySearchCondition(
		searchTerm: string,
		project: Project,
		context?: ProjectSearchContext,
	): boolean {
		const key = this.getSpecialKey(searchTerm);
		/**
		 * Handle the UNREAD special search key:
		 * Returns true if the project has an unread count greater 0 for the passed user
		 */
		if (key === SpecialSearchKey.UNREAD) {
			if (!context?.userId) {
				return false;
			}
			return this.projectUnreadsOfActiveProjectsEntities[project.id];
		}
		return false;
	}

	private projectStatusSearchCondition(
		searchTerm: string,
		project: Project,
		context?: ProjectSearchContext,
	): boolean {
		if (!context?.projectStatuses || !project.statusId) {
			return false;
		}
		const statusName = context.projectStatuses[project.statusId];
		if (!statusName) {
			return false;
		}
		return statusName.toLowerCase().includes(searchTerm.toLowerCase());
	}

	private getSpecialKey(searchTerm: string): SpecialSearchKey | null {
		const keyToBeChecked = searchTerm.toLowerCase();
		const fittingKey = findKey(SPECIAL_SEARCH_KEYS, (value) => value.includes(keyToBeChecked));
		return fittingKey ? (fittingKey as SpecialSearchKey) : null;
	}

	private sortProjects(
		projects: Project[],
		accessors: ((project: Project) => string | number)[],
		order: 'asc' | 'desc' = 'asc',
	): Project[] {
		const sorted = [...projects].sort((a, b) => {
			for (const accessor of accessors) {
				const [valA, valB] = [accessor(a), accessor(b)];
				let compareValue: number;

				if (typeof valA === 'string') {
					compareValue = valA.localeCompare(valB as string, undefined, {
						numeric: true,
						sensitivity: 'base',
					});
				} else {
					compareValue = (valA as number) - (valB as number);
				}

				if (compareValue === 0 && last(accessors) !== accessor) {
					continue;
				}

				return compareValue;
			}
		});
		return order === 'asc' ? sorted : sorted.reverse();
	}

	private initProjectUnreads(): void {
		this.store.select(selectProjectUnreadsOfActiveProjectsEntities).subscribe((projectUnreads) => {
			this.projectUnreadsOfActiveProjectsEntities = projectUnreads;
		});
	}
}
