import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpBidsComponent } from './op-bids.component';

@NgModule({
	declarations: [OpBidsComponent],
	imports: [
		CommonModule
	],
	exports: [
		OpBidsComponent
	],
})
export class OpBidsModule { }
