import { AfterViewInit, Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@injectables/services/auth/auth.service';
import { firstValueFrom } from 'rxjs';
import { Environment, ENVIRONMENT } from '@shared/tokens/environment';
import { MfeHelperService } from '@injectables/services/mfe-helper/mfe-helper.service';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';

@Component({
	selector: 'app-mfe-subscription-checkout',
	templateUrl: './mfe-subscription-checkout.component.html',
	standalone: true,
})
export class MfeSubscriptionCheckoutComponent implements AfterViewInit {
	MFE_SCRIPT_URL = this.environment.frontendComponents.scripts.subscriptionCheckout;
	MFE_TAG = 'craftnote-subscription-checkout';
	@ViewChild('componentContainer') element: ElementRef;
	authToken: string;
	priceId: string;

	constructor(
		@Inject(ENVIRONMENT) private readonly environment: Environment,
		private readonly renderer: Renderer2,
		private readonly authService: AuthService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly mfeHelperService: MfeHelperService,
		private readonly errorHandlerService: ErrorHandlerService,
	) {}

	async ngAfterViewInit(): Promise<void> {
		const params = await firstValueFrom(this.route.queryParams);
		this.priceId = params.priceId;
		this.authToken = await firstValueFrom(this.authService.authToken());
		this.mfeHelperService.loadScript(
			this.MFE_SCRIPT_URL,
			this.MFE_TAG,
			() => {
				void this.injectComponent();
			},
			() => {
				this.errorHandlerService.handleError(
					`Error Loading in script ${this.MFE_SCRIPT_URL} from ${this.MFE_TAG}`,
				);
			},
		);
	}

	private injectComponent = async (): Promise<void> => {
		let webComponent = this.mfeHelperService.getMfe(this.MFE_TAG);
		if (!webComponent) {
			webComponent = this.renderer.createElement(this.MFE_TAG);
		}
		this.renderer.setAttribute(webComponent, 'auth-token', this.authToken);
		this.renderer.setAttribute(webComponent, 'price-id', this.priceId);
		this.renderer.setAttribute(webComponent, 'language', this.mfeHelperService.getLanguage());
		const isDarkModeEnabled = await this.mfeHelperService.isDarkModeEnabled();
		this.renderer.setAttribute(webComponent, 'dark-mode', isDarkModeEnabled ? 'true' : 'false');
		this.renderer.listen(webComponent, 'close', this.onCloseClicked);
		this.renderer.listen(
			webComponent,
			'purchaseConfirmationCTAClicked',
			this.onConfirmationCTAClicked,
		);
		this.renderer.listen(webComponent, 'reloadTriggered', this.onReloadTriggered);
		this.renderer.listen(
			webComponent,
			'companyAlreadyHasAPurchase',
			this.onCompanyAlreadyHasAPurchase,
		);

		const stripeContainer = this.renderer.createElement('div');
		this.renderer.setAttribute(stripeContainer, 'id', 'js-stripe-checkout');
		this.renderer.setAttribute(stripeContainer, 'slot', 'stripe-checkout');

		this.renderer.appendChild(webComponent, stripeContainer);
		this.renderer.appendChild(this.element.nativeElement, webComponent);
	};

	private onConfirmationCTAClicked = (event: any): void => {
		const detail = event?.detail;
		if (detail === 'LICENSE_ALLOCATION') {
			return void this.router.navigateByUrl('/settings/subscription');;
		}
		return void this.router.navigateByUrl('/');
	};

	private onCloseClicked = (_): void => {
		void this.router.navigateByUrl('/settings/subscription/products');
	};

	private onReloadTriggered = (_): void => {
		window.location.reload();
	};

	private onCompanyAlreadyHasAPurchase = (_): void => {
		void this.router.navigateByUrl('/settings/subscription');
	};
}
