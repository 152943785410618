import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, firstValueFrom, Subject } from 'rxjs';
import {
	distinctUntilChanged,
	filter,
	map,
	startWith,
	switchMap,
	take,
	takeUntil,
} from 'rxjs/operators';
import { AuthService } from '@injectables/services/auth/auth.service';
import { ProjectService } from '@injectables/services/project/project.service';
import { Company } from '@shared/models/company.model';
import { ProjectActionDialogComponent } from '../project/project-action-dialog/project-action-dialog.component';
import { BillingAddressModel } from './billing-address.model';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { UpdateProjectStatusDialogComponent } from '@modules/features/settings/components/update-project-status-dialog/update-project-status-dialog.component';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { selectProfile, selectProjectStatus } from '@store/selectors/app.selectors';
import {
	addContactToProjectFactory,
	removeContactFromProjectFactory,
	removeMembersFromProjectFactory,
	updateContactAddIndex,
} from '@shared/firebase/project/project-update.functions';
import { CreationExport, ExportService } from '@injectables/export/export.service';
import { currentSelectedProject } from '@store/selectors/project-files.selectors';
import { Contact, Project, ProjectType } from 'domain-entities';
import {
	getMembersOfProject,
	isInternalProjectAdmin,
	isProjectAdmin,
	isUserOwnerOrSupervisorOfProject,
} from '@shared/functions/project/project.functions';
import { defaults, isEqual, noop } from 'lodash';
import moment from 'moment';
import { ProjectsProjectStatusUpdatedEventBuilder } from '@generated/events/ProjectsEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	selectActiveProjectEntity,
	selectCurrentUserAsMemberInCurrentProject,
} from '@store/selectors/projects.selectors';
import { TranslateService } from '@ngx-translate/core';
import { ARCHIVE_ROUTE_SEGMENT, PROJECTS_ROUTE_SEGMENT } from '@constants/routing.constants';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UnsavedChanges } from '@shared/models/has-unsaved-changes.model';
import { NO_PROJECT_STATUS } from '@shared/models/project.type';
import { setSelectedFolderAction } from '@store/actions/project.actions';
import { selectIsProjectArchiveRoute } from '@store/selectors/route.selectors';
import { LocalStorageService } from '@injectables/services/local-storage.service';

interface ProjectEditForm {
	name: string;
	startDate: Date;
	endDate: Date;
	zipcode: string;
	country: string;
	city: string;
	street: string;
	orderNumber: string;
	colorTag: string;
	statusId?: string;
}

const TRIVIAL_PROJECT_FORM_KEYS: (keyof Project & string)[] = [
	'name',
	'zipcode',
	'country',
	'city',
	'street',
	'orderNumber',
	'colorTag',
	'statusId',
	'noteContent',
];

@Component({
	selector: 'app-project-edit',
	templateUrl: './project-edit.component.html',
	styleUrls: ['./project-edit.component.scss'],
})
export class ProjectEditComponent implements OnInit, OnDestroy, UnsavedChanges {
	@Input() projectCompany: Company;
	@Output() onBack: EventEmitter<any> = new EventEmitter();

	title: string;
	description: string;
	project: Project;

	get archived(): boolean {
		return this.project.status?.name === 'archived';
	}

	ProjectType = ProjectType;

	projectFormDefaultValues: ProjectEditForm;

	projectForm = this.formBuilder.group({
		name: [null, Validators.required],
		startDate: [null],
		endDate: [null],
		zipcode: [null],
		country: [null],
		city: [null],
		street: [null],
		orderNumber: [null],
		noteContent: [null],
		colorTag: [null],
		statusId: [null],
	});

	billingAddressModel = new BillingAddressModel();
	contactsListVisible = false;
	addContactViewVisible = false;
	contactViewVisible = false;
	billingAddressViewVisible: boolean;

	contacts: Contact[];
	activeContact: any;
	activeContactIndex: number;

	projectStatuses$ = this.store.select(selectProjectStatus);

	projectStatusName$ = combineLatest([
		this.projectStatuses$,
		this.projectForm.valueChanges.pipe(startWith(null as unknown)),
	]).pipe(
		map(([projectStatuses, _]) => {
			if (
				this.projectForm.controls['statusId'].value === NO_PROJECT_STATUS ||
				!this.projectForm.controls['statusId'].value
			) {
				return this.translateService.instant('settings.projectStatus.dialogs.update.no-status');
			}

			return (
				projectStatuses.find((status) => status.id === this.projectForm.controls['statusId'].value)
					?.name || ''
			);
		}),
	);

	private destroy$ = new Subject<void>();

	get isProject(): boolean {
		return this.project?.projectType === ProjectType.PROJECT;
	}

	get titleKey(): string {
		return this.isProject
			? 'project.details.edit-project-title'
			: 'project.details.edit-folder-title';
	}

	get nameKey(): string {
		return this.isProject ? 'project.name' : 'folder.name';
	}

	get isActiveFolderProjectInArchive(): boolean {
		return (
			!this.archived &&
			this.project.projectType === ProjectType.FOLDER &&
			this.activatedRoute.snapshot?.root.children[0].url[0].path === ARCHIVE_ROUTE_SEGMENT
		);
	}

	private activeProject$ = this.store
		.select(selectActiveProjectEntity)
		.pipe(filter<Project>(Boolean));

	isExternalMemberOfProject$ = this.activeProject$.pipe(
		switchMap((project) => this.projectService.isExternalMemberOfProject$(project)),
	);

	isOwnerOrInternalSupervisorOfProject$ = this.store
		.select(selectCurrentUserAsMemberInCurrentProject)
		.pipe(map(isInternalProjectAdmin));

	isOwnerOrSupervisorOfProject$ = this.store
		.select(selectCurrentUserAsMemberInCurrentProject)
		.pipe(map(isProjectAdmin));

	canUserLeaveProject$ = combineLatest([
		this.activeProject$,
		this.isOwnerOrInternalSupervisorOfProject$,
	]).pipe(
		map(([activeProject, isOwnerOrInternalSupervisorOfProject]) => {
			// Since leaving is the only way for non-admins to get rid of a project they can always do so
			if (!isOwnerOrInternalSupervisorOfProject) {
				return true;
			}

			const numberOfInternalAdminsInProject = getMembersOfProject(activeProject).filter((member) =>
				isInternalProjectAdmin(member),
			).length;

			// An internal admin can only leave if there is at least one other
			return numberOfInternalAdminsInProject > 1;
		}),
	);

	constructor(
		private readonly localStorageService: LocalStorageService,
		private readonly router: Router,
		private readonly projectService: ProjectService,
		public readonly authService: AuthService,
		public readonly dialog: MatDialog,
		private readonly projectHelperService: ProjectHelperService,
		private readonly exportService: ExportService,
		private readonly store: Store<AppState>,
		private readonly trackingService: TrackingService,
		private readonly translateService: TranslateService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly formBuilder: UntypedFormBuilder,
	) {}

	ngOnInit(): void {
		this.initProjectChangesSubscriptions();
		this.disableFormFields();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	initProjectChangesSubscriptions(): void {
		const currentProject$ = this.store
			.select(currentSelectedProject)
			.pipe(filter<Project>(Boolean), takeUntil(this.destroy$));

		currentProject$
			.pipe(
				distinctUntilChanged((a, b) => {
					return a.id === b.id;
				}),
			)
			.subscribe((project) => {
				this.project = project;
				this.projectToForm(this.project);
				this.setProjectFormDefaultValues();
			});

		currentProject$.subscribe((project) => {
			this.contacts = project.contacts;
		});
	}

	onSubmit(): void {
		this.projectService
			.getProject(this.project.id)
			.pipe(take<Project>(1))
			.subscribe(async (project) => {
				const updateObject = this.formToProject();

				void this.trackStatusUpdateEvent(project);

				await this.projectService.updateProjectPartial(
					this.project.id,
					updateObject,
					'project.updateSuccess',
				);
				this.setProjectFormDefaultValues();
			});
	}

	isOwnerOrSupervisor(): boolean {
		return (
			this.project &&
			isUserOwnerOrSupervisorOfProject(this.authService.currentUserId(), this.project)
		);
	}

	projectToForm(project: Project): void {
		this.billingAddressModel = {
			billingName: project.billingName,
			billingEmail: project.billingEmail,
			billingStreet: project.billingStreet,
			billingCity: project.billingCity,
			billingZipcode: project.billingZipcode,
			billingCountry: project.billingCountry,
		};

		this.projectForm.controls['startDate'].setValue(
			project.startDate ? moment.unix(project.startDate).toDate() : null,
		);
		this.projectForm.controls['endDate'].setValue(
			project.endDate ? moment.unix(project.endDate).toDate() : null,
		);
		for (const key of TRIVIAL_PROJECT_FORM_KEYS) {
			this.projectForm.controls[key].setValue(project[key] ?? null);
		}
	}

	formToProject(): Partial<Project> {
		const updateObject: Partial<Project> = {};
		const startDate = this.projectForm.controls['startDate'].value;
		const endDate = this.projectForm.controls['endDate'].value;

		updateObject.startDate = startDate ? moment(startDate).unix() : undefined;
		updateObject.endDate = endDate ? moment(endDate).unix() : undefined;

		for (const key of TRIVIAL_PROJECT_FORM_KEYS) {
			let formValue = this.projectForm.controls[key].value;
			if (formValue === null) {
				continue;
			}
			if (key === 'statusId' && formValue === NO_PROJECT_STATUS) {
				formValue = null;
			}
			updateObject[key as string] = formValue;
		}
		return updateObject;
	}

	openContactsList(): void {
		this.contactsListVisible = true;
	}

	closeContactsDialog(): void {
		this.contactsListVisible = false;
	}

	openAddContactDialog(): void {
		this.contactsListVisible = false;
		this.addContactViewVisible = true;
	}

	closeAddContactDialog(): void {
		this.contactsListVisible = true;
		this.addContactViewVisible = false;
	}

	async openProjectStatusDialog(): Promise<void> {
		const dialogRef = this.dialog.open(UpdateProjectStatusDialogComponent, {
			width: '500px',
			data: {
				projectName: '',
				statuses$: this.projectStatuses$,
				selectedStatusId: this.projectForm.controls['statusId'].value,
			},
		});
		const selectedStatusId = await dialogRef.afterClosed().pipe(take(1)).toPromise();
		if (!selectedStatusId) {
			return;
		}
		this.projectForm.controls['statusId'].setValue(selectedStatusId);
	}

	createContact(contact: Contact): void {
		if (!contact) {
			return;
		}
		this.projectService
			.getProject(this.project.id)
			.pipe(take<Project>(1))
			.subscribe(async (project) => {
				this.project = project;
				let updateFunction: (project: Project) => Partial<Project>;
				if (this.activeContact) {
					updateFunction = updateContactAddIndex(this.activeContactIndex, contact);
				} else {
					updateFunction = addContactToProjectFactory(contact);
				}

				await this.projectService.updateProjectTransactional(
					this.project.id,
					updateFunction,
					'project.updateSuccess',
				);
				this.addContactViewVisible = false;
				if (this.activeContact) {
					this.contactViewVisible = true;
					this.activeContact = contact;
				} else {
					this.contactsListVisible = true;
				}
			});
	}

	saveBillingAddress(billingAddress: BillingAddressModel): void {
		this.projectService
			.getProject(this.project.id)
			.pipe(take<Project>(1))
			.subscribe(async (project) => {
				this.billingAddressModel = billingAddress;
				this.billingAddressViewVisible = false;
				this.project = project;
				const updateObject: Partial<Project> = {};
				updateObject.billingName = billingAddress.billingName;
				updateObject.billingEmail = billingAddress.billingEmail;
				updateObject.billingStreet = billingAddress.billingStreet;
				updateObject.billingCity = billingAddress.billingCity;
				updateObject.billingZipcode = billingAddress.billingZipcode;
				updateObject.billingCountry = billingAddress.billingCountry;
				return this.projectService.updateProjectPartial(this.project.id, updateObject);
			});
	}

	isProjectVisible(): boolean {
		return !(
			this.contactsListVisible ||
			this.addContactViewVisible ||
			this.contactViewVisible ||
			this.billingAddressViewVisible
		);
	}

	openContactDialog(contact): void {
		this.contactViewVisible = true;
		this.contactsListVisible = false;
		this.activeContactIndex = this.contacts?.indexOf(contact);
		this.activeContact = contact;
	}

	closeContactViewDialog(): void {
		this.contactViewVisible = false;
		this.contactsListVisible = true;
		this.activeContact = null;
		this.activeContactIndex = -1;
	}

	openEditContactDialog(): void {
		this.contactViewVisible = false;
		this.contactsListVisible = false;
		this.addContactViewVisible = true;
	}

	openBillingAddressDialog(): void {
		this.billingAddressViewVisible = true;
		this.contactsListVisible = false;
	}

	closeBillingAddressDialog(): void {
		this.billingAddressViewVisible = false;
	}

	async deleteContact(contact): Promise<void> {
		if (!contact) {
			return;
		}
		const updateFunction = removeContactFromProjectFactory(contact);
		await this.projectService.updateProjectTransactional(
			this.project.id,
			updateFunction,
			'project.updateSuccess',
		);
		this.contactViewVisible = false;
		this.addContactViewVisible = false;
		this.contactsListVisible = true;
		this.activeContact = null;
		this.activeContactIndex = -1;
	}

	async restoreProject(): Promise<void> {
		const projectToRestore = this.project;

		const result = await this.projectService.unarchiveProject(projectToRestore);
		if (!result) {
			return;
		}

		if (result !== 'unarchive-folder' && projectToRestore.parentProject) {
			await this.router.navigate(['archive', projectToRestore.parentProject]);
		} else {
			await this.projectHelperService.goToHome(null, true);
		}
	}

	async archiveProject(): Promise<void> {
		if (this.isActiveFolderProjectInArchive) {
			return;
		}

		const projectToArchive = this.project;

		const archivationResult = await this.projectService.archiveProject(projectToArchive);
		if (!archivationResult) {
			return;
		}

		this.projectHelperService.setLastOpenedProject(null);

		if (projectToArchive.parentProject) {
			await this.router.navigate(['projects', projectToArchive.parentProject]);
		} else {
			this.projectHelperService.goToHome();
		}
	}

	private async trackStatusUpdateEvent(project: Project): Promise<void> {
		if (
			!this.projectForm.controls['statusId'].value ||
			this.projectForm.controls['statusId'].value === project.statusId
		) {
			return;
		}

		await this.trackingService.trackEvent(
			new ProjectsProjectStatusUpdatedEventBuilder({
				projectId: project.id,
				isInitial: !project.statusId,
			}),
		);
	}

	async deleteProject(): Promise<void> {
		if (this.isActiveFolderProjectInArchive) {
			return;
		}
		const result = await this.projectService.deleteProject(this.project);
		if (!result) {
			return;
		}
		if (this.project.parentProject) {
			this.projectHelperService.setLastOpenedProject(this.project.parentProject);
		} else {
			this.projectHelperService.setLastOpenedProject(null);
		}

		if (!this.isProject) {
			this.store.dispatch(setSelectedFolderAction({ folderId: null }));
		}

		const isArchiveRoute = await firstValueFrom(this.store.select(selectIsProjectArchiveRoute));

		if (this.project.parentProject) {
			await this.router.navigate([
				isArchiveRoute ? ARCHIVE_ROUTE_SEGMENT : PROJECTS_ROUTE_SEGMENT,
				this.project.parentProject,
			]);
			return;
		}

		return this.projectHelperService.goToHome(null, isArchiveRoute);
	}

	showLeaveProjectDialog(): void {
		const dialogRef = this.dialog.open(ProjectActionDialogComponent, {
			data: {
				title:
					this.project.projectType === ProjectType.PROJECT
						? 'project.actions.leave.dialog.title'
						: 'folder.actions.leave.dialog.title',
				content:
					this.project.projectType === ProjectType.PROJECT
						? 'project.actions.leave.dialog.approval'
						: 'folder.actions.leave.dialog.approval',
				name: this.project.name,
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.leaveProject();
			}
		});
	}

	async leaveProject(): Promise<void> {
		const currentUser = await this.store.select(selectProfile).pipe(take(1)).toPromise();

		const member = this.project.members[currentUser.email];
		if (!member) {
			return;
		}
		const updateFunction = removeMembersFromProjectFactory([member]);
		await this.projectService
			.updateProjectTransactional(this.project.id, updateFunction)
			.catch(noop);

		this.localStorageService.remove('project');
		await this.router.navigate(['projects'], { replaceUrl: true });
	}

	async exportData(): Promise<void> {
		const exportEntity: CreationExport = {
			format: 'application/zip',
			resourceId: this.project.id,
			resourceType: 'project',
			filter: {},
		};

		await this.exportService.createExport(exportEntity);
	}

	onNoteUpdated(newNote: string): void {
		this.projectForm.controls['noteContent'].setValue(newNote);
	}

	async exportChat(): Promise<void> {
		const exportEntity: CreationExport = {
			format: 'application/pdf',
			resourceId: this.project.id,
			resourceType: 'project',
			filter: {},
		};
		await this.exportService.createExport(exportEntity);
	}

	hasUnsavedChanges(): boolean {
		const projectDefaultsMask: Partial<Project> = {
			name: '',
			startDate: null,
			endDate: null,
			zipcode: '',
			country: '',
			city: '',
			street: '',
			orderNumber: '',
			colorTag: '',
			statusId: '',
		};
		const currentValues = { ...this.projectForm.getRawValue() };
		const projectBefore = defaults(this.projectFormDefaultValues, projectDefaultsMask);
		const projectAfter = defaults(currentValues, projectDefaultsMask);

		return isEqual(projectBefore, projectAfter);
	}

	private setProjectFormDefaultValues(): void {
		this.projectFormDefaultValues = {
			...this.projectForm.getRawValue(),
		};
	}

	private disableFormFields(): void {
		combineLatest([this.isOwnerOrSupervisorOfProject$, this.isExternalMemberOfProject$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([isAdmin, isExternal]) => {
				if (isAdmin) {
					this.projectForm.enable();
				} else {
					this.projectForm.disable();
				}

				if (isExternal) {
					this.projectForm.controls['statusId'].disable();
				} else {
					this.projectForm.controls['statusId'].enable();
				}
			});
	}
}
