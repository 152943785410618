import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirebaseStatusService } from '@injectables/services/firebase-status/firebase-status.service';
import { forkJoin, Observable, of, throwError, take } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Store } from '@ngrx/store';
import { selectAllActiveProjects } from '@store/selectors/projects.selectors';
import { AuthService } from '@injectables/services/auth/auth.service';

interface ConnectivityStatus {
	name: string;
	status: 'checking' | 'success' | 'error';
	error?: string;
	isUrlCheck?: boolean;
	progress?: number;
}

interface ProjectCountStatus {
	color: 'green' | 'yellow' | 'red';
	message?: string;
	icon?: string;
}

//convert to object and allow an expected status code for success
const urlsToCheck = [
	{
		url: 'https://europe-west1-craftnote-development.cloudfunctions.net/listNotifications',
		expectedStatusCode: 400,
	},
	{
		url: 'https://api-kjzbsdf.bff.web.craftnote.dev/projects',
		expectedStatusCode: 401,
	}
];

@Component({
	selector: 'app-connectivity-check',
	templateUrl: './connectivity-check.component.html',
	styleUrls: ['./connectivity-check.component.scss'],
	standalone: true,
	imports: [CommonModule, MatProgressBarModule],
})
export class ConnectivityCheckComponent implements OnInit {
	statuses: ConnectivityStatus[] = [];
	projectCount$: Observable<number>;
	projectCountStatus$: Observable<ProjectCountStatus>;
	isLoggedIn$: Observable<boolean>;

	constructor(
		private firebaseStatus: FirebaseStatusService,
		private store: Store,
		private authService: AuthService,
	) {
		this.isLoggedIn$ = this.authService.$firebaseUser.pipe(
			map(user => user !== null)
		);
		this.projectCount$ = this.store.select(selectAllActiveProjects).pipe(
			map(projects => projects.length)
		);
		this.projectCountStatus$ = this.projectCount$.pipe(
			map(count => this.getProjectCountStatus(count))
		);
	}

	private getProjectCountStatus(count: number): ProjectCountStatus {
		if (count > 500) {
			return {
				color: 'red',
				message: 'Achtung! Die hohe Anzahl an aktiven Projekten (über 500) kann zu deutlichen Performance-Einbußen führen. Bitte archiviere nicht benötigte Projekte.'
			};
		} else if (count > 200) {
			return {
				color: 'yellow',
				message: 'Hey! Du hast über 200 aktive Projekte. Das könnte die Performance beeinträchtigen. Vielleicht archivierst du mal die nicht mehr benötigten?'
			};
		}
		return { 
			color: 'green',
			icon: '✅'
		};
	}

	ngOnInit() {
		this.checkConnectivity();
	}

	checkConnectivity() {
		this.initializeStatuses();
		console.info('Starting connectivity checks...');

		const checks = [
			this.checkFirestore(),
			this.checkRealtimeDatabase(),
			this.checkCloudFunctions(),
			this.checkStorage(),
			...this.checkUrls(),
		];

		forkJoin(checks).subscribe();
	}

	private initializeStatuses() {
		this.statuses = [
			{ name: 'Firestore', status: 'checking' },
			{ name: 'Realtime Database', status: 'checking' },
			{ name: 'Cloud Functions', status: 'checking' },
			{ name: 'Storage', status: 'checking' },
			{ name: 'URL-Prüfungen', status: 'checking', isUrlCheck: true },
		];
	}

	private checkFirestore(): Observable<void> {
		console.debug('Checking Firestore connectivity...');
		return this.firebaseStatus.checkFirestoreConnectivity().pipe(
			map(() => {
				console.info('Firestore check successful');
				this.updateStatus('Firestore', 'success');
			}),
			catchError((error) => {
				console.error('Firestore check failed:', error);
				this.updateStatus('Firestore', 'error', error.message);
				return of(undefined);
			}),
		);
	}

	private checkRealtimeDatabase(): Observable<void> {
		console.debug('Checking Realtime Database connectivity...');
		return this.firebaseStatus.checkDatabaseConnectivity().pipe(
			take(1),
			timeout({
				first: 5000,
				with: () => throwError(() => new Error('Database connection timed out')),
			}),
			map((data) => {
				if (!data || data.status !== 'ok') {
					throw new Error('Invalid database status');
				}
				console.info('Realtime Database check successful');
				this.updateStatus('Realtime Database', 'success');
			}),
			catchError((error) => {
				console.error('Realtime Database check failed:', error);
				this.updateStatus('Realtime Database', 'error', error.message || 'Connection failed');
				return of(undefined);
			}),
		);
	}

	private checkCloudFunctions(): Observable<void> {
		console.debug('Checking Cloud Functions connectivity...');
		return this.firebaseStatus.checkCloudFunctionsConnectivity().pipe(
			map(() => {
				console.info('Cloud Functions check successful');
				this.updateStatus('Cloud Functions', 'success');
			}),
			catchError((error) => {
				console.error('Cloud Functions check failed:', error);
				this.updateStatus('Cloud Functions', 'error', error.message);
				return of(undefined);
			}),
		);
	}

	private checkStorage(): Observable<void> {
		console.debug('Checking Storage connectivity...');
		return this.firebaseStatus.checkStorageConnectivity().pipe(
			timeout({
				first: 5000,
				with: () => throwError(() => new Error('Storage connection timed out')),
			}),
			map(() => {
				console.info('Storage check successful');
				this.updateStatus('Storage', 'success');
			}),
			catchError((error) => {
				console.error('Storage check failed:', error);
				this.updateStatus('Storage', 'error', error.message);
				return of(undefined);
			}),
		);
	}

	private checkUrls(): Observable<void>[] {
		let successCount = 0;
		const totalUrls = urlsToCheck.length;

		return urlsToCheck.map((urlCheck) =>
			this.checkUrl(urlCheck.url).pipe(
				map((response) => {
					if (response.status === urlCheck.expectedStatusCode) {
						successCount++;
						this.updateUrlCheckStatus(successCount, totalUrls);
					} else {
						this.updateStatus(
							urlCheck.url,
							'error',
							`Expected ${urlCheck.expectedStatusCode}, got ${response.status}`,
							true
						);
					}
				}),
				catchError((error) => {
					this.updateStatus(urlCheck.url, 'error', error.message, true);
					return of(undefined);
				}),
			),
		);
	}

	private checkUrl(url: string): Observable<Response> {
		console.debug(`Checking URL connectivity: ${url}`);
		return new Observable((observer) => {
			fetch(url)
				.then((response) => {
					observer.next(response);
					observer.complete();
				})
				.catch((error) => {
					console.error(`URL check failed: ${url}`, error);
					observer.error(error);
				});
		});
	}

	private updateUrlCheckStatus(successCount: number, totalUrls: number) {
		const urlCheckStatus = this.statuses.find(s => s.isUrlCheck);
		if (urlCheckStatus) {
			urlCheckStatus.status = successCount === totalUrls ? 'success' : 'error';
			urlCheckStatus.name = `URL-Prüfungen (${successCount}/${totalUrls} erfolgreich)`;
			urlCheckStatus.error = successCount === totalUrls ? undefined : 'Einige URL-Prüfungen sind fehlgeschlagen. Siehe Details unten.';
		}
	}

	private updateStatus(
		name: string,
		status: 'checking' | 'success' | 'error',
		error?: string,
		isUrlCheck?: boolean,
		progress?: number
	) {
		const index = this.statuses.findIndex((s) => s.name === name);
		if (index === -1) {
			this.statuses.push({ name, status, error, isUrlCheck, progress });
		} else {
			this.statuses[index] = { name, status, error, isUrlCheck, progress };
		}
	}

	async startSpeedTest() {
		if (!confirm('Dies wird eine 100MB Testdatei herunterladen. Fortfahren?')) {
			return;
		}

		this.updateStatus('Geschwindigkeitstest', 'checking');
		
		try {
			const startTime = Date.now();
			const url = await this.firebaseStatus.getSpeedTestDownloadUrl();
			const response = await fetch(url);
			const reader = response.body?.getReader();
			const contentLength = Number(response.headers.get('Content-Length')) || 0;
			
			if (!reader) {
				throw new Error('Failed to start download');
			}

			let receivedLength = 0;
			while (true) {
				const { done, value } = await reader.read();
				if (done) break;
				
				receivedLength += value.length;
				const progress = (receivedLength / contentLength) * 100;
				const elapsedSeconds = (Date.now() - startTime) / 1000;
				const speedMbps = ((receivedLength / 1024 / 1024) * 8) / elapsedSeconds;
				
				this.updateStatus(
					'Geschwindigkeitstest',
					'checking',
					`${speedMbps.toFixed(1)} Mbps`,
					false,
					progress
				);
			}

			const totalTime = (Date.now() - startTime) / 1000;
			const averageSpeed = ((contentLength / 1024 / 1024) * 8) / totalTime;
			this.updateStatus(
				'Geschwindigkeitstest',
				'success',
				`Durchschnitt: ${averageSpeed.toFixed(1)} Mbps`
			);
		} catch (error) {
			console.error('Speed test failed:', error);
			this.updateStatus(
				'Geschwindigkeitstest',
				'error',
				error instanceof Error ? error.message : 'Geschwindigkeitstest fehlgeschlagen'
			);
		}
	}
}
